<template>
  <div>
    <maca-modal titulo="Nueva Promoción" :impedirClose="impedirClose" :confirmar-close="true" ref="modal">
      <el-form :model="form" ref="form" :rules="formRules" label-width="150px">
        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre"></el-input>
        </el-form-item>
        <el-form-item label="Descripcion" prop="descripcion">
          <el-input v-model="form.descripcion"></el-input>
        </el-form-item>
        <el-form-item label="Rango de Fechas" prop="rangoFecha">
          <maca-input-rango-fecha v-model="form.rangoFecha"></maca-input-rango-fecha>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "nuevo-promocion",
  data() {
    return {
      form: {
        nombre: "",
        descripcion: "",
        rangoFecha: null
      },
      impedirClose: false,

      formRules: {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change"
          }
        ],
        descripcion: [
          {
            required: true,
            message: "Por favor introduzca el descripcion.",
            trigger: "change"
          }
        ],
        rangoFecha: [
          {
            required: true,
            message: "Por favor introduzca el rango de fechas.",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$eventStrings._actualizarTabla);
          this.$refs.modal.confirmarClose = false
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        nombre: this.form.nombre,
        descripcion: this.form.descripcion,
        fechaDesde: this.form.rangoFecha[0],
        fechaHasta: this.form.rangoFecha[1],
        vigente: 1
      };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/promocion/crear",
        params,
        this.$usuarioToken()
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Promoción creada con éxito!",
          type: "success"
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      }
      //

      return false;
    }
  }
};
</script>
