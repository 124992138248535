<template>
  <div style="display: flex">
    <el-date-picker
      ref="datepicker"
      v-model="fechaSeleccionada"
      type="daterange"
      format="dd/MM/yyyy"
      value-format="yyyy-MM-dd"
      :picker-options="fechaOpciones"
      @change="$emit('input', fechaSeleccionada)"
      style="width: 100%"
      :disabled="disabled"
      range-separator="-"
      start-placeholder="Desde"
      end-placeholder="Hasta"
    ></el-date-picker>
    <div class="slot-wrapper">
      <slot name="append"></slot>
    </div>
  </div>
</template>

<style scoped>
.slot-wrapper > * {
  margin-left: 10px;
}
</style>

<script>
export default {
  name: "maca-intervalo-fecha",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: null
    },
    pasadoHabilitado: {
      type: Boolean,
      default: true
    },
    futuroHabilitado: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      fechaSeleccionada: null,
      fechaOpciones: {}
    };
  },
  mounted() {
    this.fechaOpciones.disabledDate = this.diaDeshabilitado;
    this.fechaSeleccionada = this.value;
  },
  methods: {
    diaDeshabilitado(time) {
      if (!this.pasadoHabilitado) {
        return time.getTime() < Date.now() - 3600 * 1000 * 24;
      }
      if (!this.futuroHabilitado) {
        return time.getTime() > Date.now();
      }

      return false;
    }
  },
  watch: {
    value: {
      handler: function() {
        //form.resetFields deja el valor como un array con 1 elemento nulo ([null])
        //corregir eso aqui
        if (this.value != null && this.value.length == 1) {
          this.$emit("input", null);
          this.fechaSeleccionada = null;
        } else {
          this.fechaSeleccionada = this.value;
        }
      }
    }
  }
};
</script>
