var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: { titulo: "Datos de Calendario", "confirmar-close": false }
        },
        [
          _vm.datos
            ? _c("table", { staticClass: "tabla-info" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Promocion")]),
                    _c("td", {
                      domProps: { textContent: _vm._s(_vm.datos.promocion) }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Fecha Desde")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$common.formatearFecha(_vm.datos.fechaDesde)
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Fecha Hasta")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$common.formatearFecha(_vm.datos.fechaHasta)
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Hora Desde")]),
                    _c("td", {
                      domProps: { textContent: _vm._s(_vm.datos.horaDesde) }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Hora Hasta")]),
                    _c("td", {
                      domProps: { textContent: _vm._s(_vm.datos.horaHasta) }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Provincia")]),
                    _c("td", {
                      domProps: { textContent: _vm._s(_vm.datos.provincia) }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Localidad")]),
                    _c("td", {
                      domProps: { textContent: _vm._s(_vm.datos.localidad) }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Premios")]),
                    _c("td", [
                      _vm.datos.detalleCalendario != null
                        ? _c(
                            "div",
                            _vm._l(_vm.datos.detalleCalendario, function(
                              itemDC
                            ) {
                              return _c("li", [
                                _c("b", [_vm._v(_vm._s(itemDC.tipoPremio))]),
                                _vm._v(" | "),
                                _c("b", [_vm._v("Cant. Total:")]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(itemDC.cantidadPremioDisponible) +
                                    " |\n                "
                                ),
                                _c("b", [_vm._v("Cant. Canjeada:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(itemDC.cantidadPremioCanjea) +
                                    "\n              "
                                )
                              ])
                            }),
                            0
                          )
                        : _vm._e()
                    ])
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Modo")]),
                    _c("td", [
                      _vm.datos.salidaPorMinuto === 1
                        ? _c("span", [
                            _vm._v("Por "),
                            _c("b", [_vm._v("Minutos")])
                          ])
                        : _vm._e(),
                      _vm.datos.salidaPorCodigo === 1
                        ? _c("span", [
                            _vm._v("Por "),
                            _c("b", [_vm._v("Códigos Canjeados")])
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Configuración")]),
                    _c("td", [
                      _vm.datos.salidaPorCodigo == 1
                        ? _c("p", [
                            _vm._v("\n              Saldrá "),
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.datos.valorSalidaPremio) + " premios"
                              )
                            ]),
                            _vm._v(" cada\n              "),
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.datos.valorSalidaCantidad) +
                                  " códigos canjeados"
                              )
                            ])
                          ])
                        : _c("p", [
                            _vm._v("\n              Saldrá "),
                            _c("b", [
                              _vm._v(_vm._s(_vm.datos.valorSalidaPremio))
                            ]),
                            _vm._v(" premios cada\n              "),
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.datos.valorSalidaCantidad) +
                                  " minutos"
                              )
                            ])
                          ])
                    ])
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Cantidad Total")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(_vm.datos.cantidadPremioTotal)
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Cantidad Canjeada")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(_vm.datos.cantidadPremioCanjeado)
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Cantidad Restante")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(_vm.datos.cantidadPremioRestante)
                      }
                    })
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }