<template>
  <div style="display: flex">
    <el-time-select
      placeholder="Desde las..."
      v-model="horaDesde"
      :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:55',
              maxTime: horaHasta
            }"
    ></el-time-select>
    <div style="width: 30px; text-align: center;">
      <span>-</span>
    </div>
    <el-time-select
      placeholder="Hasta las..."
      v-model="horaHasta"
      :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:55',
              minTime: horaDesde
            }"
    ></el-time-select>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      horaDesde: "",
      horaHasta: ""
    };
  },
  watch: {
    horaDesde() {
      if (
        this.horaHasta == null ||
        this.horaHasta == "" ||
        this.horaDesde == null ||
        this.horaDesde == ""
      ) {
        this.$emit("input", null);
        return;
      }
      this.$emit("input", [this.horaDesde, this.horaHasta]);
    },
    horaHasta() {
      if (
        this.horaHasta == null ||
        this.horaHasta == "" ||
        this.horaDesde == null ||
        this.horaDesde == ""
      ) {
        this.$emit("input", null);
        return;
      }
      this.$emit("input", [this.horaDesde, this.horaHasta]);
    },
    value() {
      if (this.value == null || this.value.length < 2) {
        this.horaDesde = null;
        this.horaHasta = null;
        return;
      }

      this.horaDesde = this.value[0];
      this.horaHasta = this.value[1];
    }
  }
};
</script>