<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3><i class="el-icon-user"></i>Participantes</h3>
      </div>
    </div>
    <el-card :body-style="{ padding: '20px' }">
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column>
            <template slot="header">
              <maca-datatable-filtrar-rangofecha
                label="Fecha Canje"
                v-model="filtroFecha"
              ></maca-datatable-filtrar-rangofecha>
            </template>
            <template slot-scope="props">
              <span v-text="$common.formatearFechaHora(props.row.fecha)"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="160">
            <template slot="header">
              <maca-datatable-buscar-select
                label="Promoción"
                v-model="filtroPromocion"
                :urlSelect="urlPromocion"
              ></maca-datatable-buscar-select>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.promocion"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="100">
            <template slot="header">
              <maca-datatable-buscar-select
                label="Provincia"
                v-model="filtroProvincia"
                :urlSelect="urlProvincia"
              ></maca-datatable-buscar-select>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.provincia"></span>
            </template>
          </el-table-column>
          <el-table-column label="Localidad" :min-width="100">
            <template slot-scope="props">
              <span v-text="props.row.localidad"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="80">
            <template slot="header">
              <maca-datatable-buscar
                label="Codigo"
                v-model="filtroCodigo"
              ></maca-datatable-buscar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.codigoCanjea"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="105" label="Cod. Vto.">
            <template slot="header">
              <maca-datatable-buscar
                label="Cod. Vto."
                v-model="filtroCodigoCanjeaVto"
              ></maca-datatable-buscar>
            </template>
            <template slot-scope="props">
              {{ props.row.codigoCanjeaVto }}
            </template>
          </el-table-column>

          <el-table-column :min-width="105" label="Cod. Lote">
            <template slot="header">
              <maca-datatable-buscar
                label="Cod. Lote"
                v-model="filtroCodigoCanjeaLote"
              ></maca-datatable-buscar>
            </template>
            <template slot-scope="props">
              {{ props.row.codigoCanjeaLote }}
            </template>
          </el-table-column>
          <el-table-column :min-width="80">
            <template slot="header">
              <maca-datatable-buscar
                label="Dni"
                v-model="filtroDni"
              ></maca-datatable-buscar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.clienteDNI"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="120">
            <template slot="header">
              <maca-datatable-buscar
                label="Persona"
                v-model="filtroNombre"
              ></maca-datatable-buscar>
            </template>
            <template slot-scope="props">
              <span
                v-if="props.row.clienteID != null"
                v-text="props.row.clienteNombreCompleto"
              ></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="80">
            <template slot="header">
              <maca-datatable-buscar
                label="Telefono"
                v-model="filtroTelefono"
              ></maca-datatable-buscar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.telefono"></span>
            </template>
          </el-table-column>
          <el-table-column label="Premio">
            <template slot-scope="props">
              <span v-text="props.row.premio"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="100">
            <template slot="header">
              <maca-datatable-buscar-select
                label="Estado"
                v-model="filtroEstadoCanje"
                :urlSelect="urlEstadoCanje"
              ></maca-datatable-buscar-select>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.estadoCanje"></span>
            </template>
          </el-table-column>

          <el-table-column :min-width="100" label="Ganador">
            <template slot-scope="props">
              <el-tag :type="props.row.ganador == 1 ? 'success' : 'danger'">{{
                props.row.ganador == 1 ? "Si" : "No"
              }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="Ver Estados" width="75">
            <template slot-scope="props">
              <el-button
                v-if="props.row.ganador == 1"
                type="info"
                size="small"
                plain
                @click="$refs.modalHistorial.abrir(props.row.id)"
                round
              >
                <i class="el-icon-truck"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Nuevo Estado" width="70">
            <template slot-scope="props">
              <el-button
                v-if="props.row.ganador == 1"
                type="primary"
                size="small"
                icon="el-icon-add-location"
                @click="$refs.modalNuevoEstado.abrir(props.row.id)"
                plain
                round
              ></el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-historial ref="modalHistorial"></modal-historial>
    <modal-nuevo-estado
      ref="modalNuevoEstado"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo-estado>
  </div>
</template>

  <script>
import ModalHistorial from "./modales/historial";
import ModalNuevoEstado from "./modales/nuevo_estado";

export default {
  name: "participante",
  components: {
    ModalHistorial,
    ModalNuevoEstado,
  },
  data() {
    return {
      urlTabla: this.$api.URL + "/canje/obtenerTodos",
      paramsTabla: [],
      actualizarTabla: true,
      bloquearTabla: true,

      filtroFecha: null,
      filtroNombre: null,
      filtroDni: null,
      filtroTelefono: null,
      filtroPromocion: null,
      filtroEstadoCanje: null,
      filtroProvincia: null,
      filtroCodigo: null,
      filtroCodigoCanjeaVto: null,
      filtroCodigoCanjeaLote: null,

      urlPromocion: this.$api.URL + "/promocion/obtenerTodosSelect",
      urlProvincia: this.$api.URL + "/provincia/obtenerTodos?paisID=1",
      urlEstadoCanje: this.$api.URL + "/estadoCanje/obtenerTodosSelect",
    };
  },
  created() {
    let hoy = this.$moment(new Date()).format("YYYY-MM-DD");
    let sieteDiasAtras = this.$moment(new Date())
      .subtract(7, "days")
      .format("YYYY-MM-DD");

    //this.filtroFecha = [sieteDiasAtras, hoy];
  },
  methods: {},
  watch: {
    filtroNombre() {
      if (this.filtroNombre != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "nombre",
          this.filtroNombre
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "nombre");
      }

      this.actualizarTabla = true;
    },
    filtroDni() {
      if (this.filtroDni != null) {
        this.$common.agregarUrlParam(this.paramsTabla, "dni", this.filtroDni);
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "dni");
      }

      this.actualizarTabla = true;
    },
    filtroCodigo() {
      if (this.filtroCodigo != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "codigoCanjea",
          this.filtroCodigo
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "codigoCanjea");
      }

      this.actualizarTabla = true;
    },
    filtroCodigoCanjeaVto() {
      if (this.filtroCodigoCanjeaVto != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "codigoCanjeaVto",
          this.filtroCodigoCanjeaVto
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "codigoCanjeaVto");
      }

      this.actualizarTabla = true;
    },
    filtroCodigoCanjeaLote() {
      if (this.filtroCodigoCanjeaLote != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "codigoCanjeaLote",
          this.filtroCodigoCanjeaLote
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "codigoCanjeaLote");
      }

      this.actualizarTabla = true;
    },
    filtroTelefono() {
      if (this.filtroTelefono != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "telefono",
          this.filtroTelefono
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "telefono");
      }

      this.actualizarTabla = true;
    },
    filtroPromocion() {
      if (this.filtroPromocion != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "promocionID",
          this.filtroPromocion.id
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "promocionID");
      }

      this.actualizarTabla = true;
    },
    filtroProvincia() {
      if (this.filtroProvincia != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "provinciaID",
          this.filtroProvincia.id
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "provinciaID");
      }

      this.actualizarTabla = true;
    },
    filtroEstadoCanje() {
      if (this.filtroEstadoCanje != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "estadoCanjeID",
          this.filtroEstadoCanje.id
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "estadoCanjeID");
      }

      this.actualizarTabla = true;
    },
    filtroFecha() {
      if (this.filtroFecha != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "fechaDesde",
          this.filtroFecha[0]
        );
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "fechaHasta",
          this.filtroFecha[1]
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "fechaDesde");
        this.$common.sacarUrlParam(this.paramsTabla, "fechaHasta");
      }

      this.actualizarTabla = true;
    },
  },
};
</script>

