var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mi-sidebar" },
    [
      _c(
        "div",
        { staticClass: "sidebar-header" },
        [
          _vm._m(0),
          _c(
            "el-popover",
            {
              ref: "popover",
              staticStyle: {
                margin: "auto",
                "margin-left": "0px",
                "margin-bottom": "5px",
                "margin-right": "0px"
              },
              attrs: { placement: "right", trigger: "click" }
            },
            [
              _c(
                "div",
                [
                  _vm.$usuario()
                    ? _c("span", {
                        domProps: { textContent: _vm._s(_vm.$usuario().email) }
                      })
                    : _vm._e(),
                  _c("br"),
                  _vm._l(_vm.$usuarioRoles(), function(item, index) {
                    return _c("span", {
                      key: index,
                      staticStyle: { display: "block" },
                      domProps: { textContent: _vm._s(item.nombre) }
                    })
                  })
                ],
                2
              ),
              _c(
                "el-button",
                {
                  staticStyle: { color: "white", width: "100%" },
                  attrs: { slot: "reference", type: "text" },
                  slot: "reference"
                },
                [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _vm.$usuario()
                      ? _c("span", {
                          staticStyle: {
                            "margin-left": "20px",
                            "text-align": "left"
                          },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$usuario().nombre +
                                " " +
                                this.$usuario().apellido
                            )
                          }
                        })
                      : _vm._e(),
                    _c("i", {
                      staticClass: "el-icon-caret-bottom",
                      staticStyle: { margin: "auto", "margin-right": "20px" }
                    })
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-menu",
        {
          staticClass: "sidebar-menu",
          attrs: {
            "background-color": "transparent",
            "default-active": _vm.itemMenuActivo,
            "default-openeds": _vm.menuItemsAbiertos
          }
        },
        [
          _c(
            "el-menu-item",
            {
              attrs: { index: "home" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/")
                }
              }
            },
            [
              _c("i", { staticClass: "el-icon-s-home" }),
              _c("span", [_vm._v("Inicio")])
            ]
          ),
          _c(
            "el-menu-item",
            {
              attrs: { index: "premios" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/premios")
                }
              }
            },
            [
              _c("i", { staticClass: "el-icon-goods" }),
              _c("span", [_vm._v("Premios")])
            ]
          ),
          _c(
            "el-menu-item",
            {
              attrs: { index: "participantes" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/participantes")
                }
              }
            },
            [
              _c("i", { staticClass: "el-icon-user" }),
              _c("span", [_vm._v("Participantes")])
            ]
          ),
          _c(
            "el-menu-item",
            {
              attrs: { index: "ganadores" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/ganadores")
                }
              }
            },
            [
              _c("i", { staticClass: "el-icon-medal" }),
              _c("span", [_vm._v("Ganadores")])
            ]
          ),
          _c(
            "el-menu-item",
            {
              attrs: { index: "calendario" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/calendario")
                }
              }
            },
            [
              _c("i", { staticClass: "el-icon-date" }),
              _c("span", [_vm._v("Calendario")])
            ]
          ),
          _vm.$usuarioEs.superadmin
            ? _c(
                "el-submenu",
                { attrs: { index: "reportes" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { staticClass: "el-icon-tickets" }),
                    _c("span", [_vm._v("Reportes")])
                  ]),
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "reportes-ganadores" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("reporteGanadores")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-medal" }),
                      _c("span", [_vm._v("Ganadores")])
                    ]
                  ),
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "reportes-participantes" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("reporteParticipantes")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-medal" }),
                      _c("span", [_vm._v("Participantes")])
                    ]
                  )
                ],
                2
              )
            : _vm._e(),
          _vm.$usuarioEs.superadmin
            ? _c(
                "el-submenu",
                { attrs: { index: "ajustes" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", { staticClass: "el-icon-setting" }),
                    _c("span", [_vm._v("Ajustes")])
                  ]),
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "provincias" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/ajustes/provincias")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-postcard" }),
                      _c("span", [_vm._v("Provincias")])
                    ]
                  ),
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "localidades" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/ajustes/localidades")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-postcard" }),
                      _c("span", [_vm._v("Localidades")])
                    ]
                  ),
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "tipospremios" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/ajustes/tipos_premio")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-goods" }),
                      _c("span", [_vm._v("Tipos de Premio")])
                    ]
                  ),
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "promocionvigente" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/ajustes/promocion_vigente")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-star-off" }),
                      _c("span", [_vm._v("Promoción Vigente")])
                    ]
                  )
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "sidebar-footer" }, [
        _c("small", { staticClass: "copyright" }, [
          _vm._v("© " + _vm._s(_vm.anioActual))
        ]),
        _c(
          "a",
          { attrs: { href: "http://macamedia.com.ar", target: "_blank" } },
          [_vm._v(" MACAMEDIA")]
        ),
        _c("br"),
        _c("small", { staticClass: "version" }, [
          _c("b", [_vm._v("Versión:")]),
          _c("span", [_vm._v(_vm._s(" 1.0.0"))])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { height: "100%", display: "flex" } }, [
      _c("img", {
        staticStyle: {
          margin: "auto",
          "margin-left": "20px",
          "max-width": "200px"
        },
        attrs: {
          src: require("../../../assets/logo-secco-blanco.png"),
          alt: "Logo Secco",
          height: "50px"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }