var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contenedor-tabla" },
    [
      _c(
        "transition",
        {
          on: {
            "after-enter": function($event) {
              _vm.refresh_anim = false
            }
          }
        },
        [
          _vm.refresh_anim
            ? _c("div", {
                staticClass: "contenedor-refresh-anim scale-in-ver-top-fade"
              })
            : _vm._e()
        ]
      ),
      _c(
        "data-tables-server",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.cargando,
              expression: "cargando"
            }
          ],
          attrs: {
            data: _vm.tableData,
            "current-page": _vm.pagActual,
            "pagination-props": _vm.paginationProps,
            pageSize: _vm.paginationProps.pageSizes[0],
            rowClassName: _vm.rowClassName,
            defaultSortData: _vm.defaultSortProp
          },
          on: {
            "update:currentPage": function($event) {
              _vm.pagActual = $event
            },
            "update:current-page": function($event) {
              _vm.pagActual = $event
            },
            "query-change": _vm.cambiarPagina
          }
        },
        [
          _vm._t("default"),
          _c("template", { slot: "append" }, [_vm._t("append")], 2)
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }