var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "contenedor-titulo" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "botones" },
          [
            _c("maca-select-box", {
              staticStyle: { width: "250px" },
              attrs: {
                icon: "el-icon-star-off",
                placeholder:
                  _vm.noHayPromocionVigente && !_vm.promocionVigente
                    ? "Promoción"
                    : "Cargando...",
                url: _vm.urlPromocion,
                disabled: _vm.cargando
              },
              model: {
                value: _vm.promocionVigente,
                callback: function($$v) {
                  _vm.promocionVigente = $$v
                },
                expression: "promocionVigente"
              }
            })
          ],
          1
        )
      ]),
      _vm.noHayPromocionVigente && !_vm.promocionVigente
        ? _c("div", { staticStyle: { height: "200px", display: "flex" } }, [
            _c("span", { staticStyle: { margin: "auto" } }, [
              _vm._v(
                "No hay una promoción vigente. Seleccione una manualmente."
              )
            ])
          ])
        : _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6 } },
                    [
                      _c(
                        "el-card",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.cargando,
                              expression: "cargando"
                            }
                          ],
                          attrs: { "body-style": { padding: "20px" } }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c("span", [_vm._v("Participantes por DNI")]),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    plain: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.modalDetParticipantes.abrir(
                                        _vm.promocionVigente.id,
                                        _vm.datosEstadisticas
                                          .cantParticipantesUnicosTotales
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Ver detalle\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm.datosEstadisticas
                            ? _c("div", [
                                _c("h4", [
                                  _vm._v(
                                    "\n              Totales:\n              "
                                  ),
                                  _c("span", {
                                    staticStyle: { "font-weight": "bold" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.datosEstadisticas
                                          .cantParticipantesUnicosTotales
                                      )
                                    }
                                  }),
                                  _vm.datosEstadisticas
                                    .cantParticipantesUnicosTotales > 0
                                    ? _c("td")
                                    : _vm._e()
                                ]),
                                _c("br"),
                                _c("table", { staticClass: "tabla-info" }, [
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.datosEstadisticas
                                        .cantParticipantesDiarios,
                                      function(item, index) {
                                        return _c("tr", { key: index }, [
                                          _c("th", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.diaNombre
                                              )
                                            }
                                          }),
                                          _c("td", {
                                            staticStyle: {
                                              "font-weight": "bold"
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.cantidadParticipantes
                                              )
                                            }
                                          }),
                                          item.cantidadParticipantes > 0
                                            ? _c(
                                                "td",
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        size: "small",
                                                        plain: "",
                                                        round: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$refs.modalDetParticipantesDiariosAgrupadoFecha.abrir(
                                                            _vm.promocionVigente
                                                              .id,
                                                            item.diaNro,
                                                            item.diaNombre,
                                                            item.cantidadParticipantes
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-zoom-in"
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]),
                                _c("p", [
                                  _c("small", [
                                    _vm._v("Los "),
                                    _c("b", [_vm._v("totales para cada día")]),
                                    _vm._v(
                                      " pueden diferir del\n                "
                                    ),
                                    _c("b", [
                                      _vm._v("total de participantes únicos")
                                    ]),
                                    _vm._v(
                                      " ya que una persona puede\n                haber participado más de una vez en diferentes días de la\n                semana"
                                    )
                                  ])
                                ])
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 9 } },
                    [
                      _c(
                        "el-card",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.cargando,
                              expression: "cargando"
                            }
                          ],
                          attrs: { "body-style": { padding: "20px" } }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [_c("span", [_vm._v("Calendario")])]
                          ),
                          _c("vc-date-picker", {
                            attrs: {
                              "disable-page-swipe": "",
                              "is-expanded": ""
                            },
                            on: { input: _vm.dayPick },
                            model: {
                              value: _vm.fechaSeleccionada,
                              callback: function($$v) {
                                _vm.fechaSeleccionada = $$v
                              },
                              expression: "fechaSeleccionada"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 9 } },
                    [
                      _c(
                        "el-card",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.cargando,
                              expression: "cargando"
                            }
                          ],
                          attrs: { "body-style": { padding: "20px" } }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c("span", [_vm._v("Códigos Por Provincia")]),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    plain: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.modalDetCodCanjeadosPorProvincia.abrir(
                                        _vm.promocionVigente.id,
                                        _vm.datosEstadisticas.cantCodigosTotales
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Ver detalle\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("codigos-provincia", {
                            attrs: { datos: _vm.datosEstadisticas }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { gutter: 20 }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 6 } },
                    [
                      _c(
                        "el-card",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.cargando,
                              expression: "cargando"
                            }
                          ],
                          attrs: { "body-style": { padding: "20px" } }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c("span", [_vm._v("Códigos Canjeados")]),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    plain: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.modalDetPorFechaParticipantesCanjes.abrir(
                                        _vm.promocionVigente.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Ver detalle\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm.datosEstadisticas
                            ? _c("div", [
                                _c("h4", [
                                  _vm._v(
                                    "\n              Totales:\n              "
                                  ),
                                  _c("span", {
                                    staticStyle: { "font-weight": "bold" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.datosEstadisticas
                                          .cantCodigosUnicosTotales
                                      )
                                    }
                                  })
                                ]),
                                _c("table", { staticClass: "tabla-info" }, [
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.datosEstadisticas.cantCodigosDiarios,
                                      function(item, index) {
                                        return _c("tr", { key: index }, [
                                          _c("th", {
                                            domProps: {
                                              textContent: _vm._s(
                                                item.diaNombre
                                              )
                                            }
                                          }),
                                          _c("td", {
                                            staticStyle: {
                                              "font-weight": "bold"
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.cantidadCodigos
                                              )
                                            }
                                          }),
                                          item.cantidadCodigos > 0
                                            ? _c(
                                                "td",
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        size: "small",
                                                        plain: "",
                                                        round: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$refs.modalDetCodCanjeadosDiariosAgrupadoFecha.abrir(
                                                            _vm.promocionVigente
                                                              .id,
                                                            item.diaNro,
                                                            item.diaNombre,
                                                            item.cantidadCodigos
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-zoom-in"
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 9 } },
                    [
                      _c(
                        "el-card",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.cargando,
                              expression: "cargando"
                            }
                          ],
                          attrs: { "body-style": { padding: "20px" } }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c("span", [_vm._v("Premios/Ganadores")]),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    plain: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.modalDetPorFechaGanadores.abrir(
                                        _vm.promocionVigente.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Ver detalle\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm.datosEstadisticas
                            ? _c("div", [
                                _c("h4", [
                                  _vm._v(
                                    "\n              Salidos:\n              "
                                  ),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.datosEstadisticas
                                          .cantPremiosTotalCanjeada
                                      )
                                    }
                                  })
                                ]),
                                _c("h4", [
                                  _vm._v(
                                    "\n              % Salidos:\n              "
                                  ),
                                  _vm.datosEstadisticas
                                    .cantPremiosTotalCanjeada > 0
                                    ? _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            parseFloat(
                                              _vm.datosEstadisticas
                                                .cantPremiosTotal /
                                                _vm.datosEstadisticas
                                                  .cantPremiosTotalCanjeada
                                            ).toFixed(2) + "%"
                                          )
                                        }
                                      })
                                    : _c("span", [_vm._v("0")])
                                ]),
                                _c("br"),
                                _c("h4", [
                                  _vm._v(
                                    "\n              Importe Salidos:\n              "
                                  ),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.datosEstadisticas
                                          .importePremiosTotalCanjeado
                                      )
                                    }
                                  })
                                ]),
                                _c("h4", [
                                  _vm._v(
                                    "\n              % Importe Salidos:\n              "
                                  ),
                                  _vm.datosEstadisticas
                                    .cantPremiosTotalCanjeada > 0
                                    ? _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            parseFloat(
                                              _vm.datosEstadisticas
                                                .importePremiosTotal /
                                                _vm.datosEstadisticas
                                                  .importePremiosTotalCanjeado
                                            ).toFixed(2) + "%"
                                          )
                                        }
                                      })
                                    : _c("span", [_vm._v("0")])
                                ]),
                                _c("br"),
                                _c("h4", [
                                  _vm._v(
                                    "\n              Grado Avance Salidos:\n              "
                                  ),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.datosEstadisticas
                                          .gradoAvancePorcentajeCantidadPremios
                                      )
                                    }
                                  })
                                ]),
                                _c("h4", [
                                  _vm._v(
                                    "\n              Grado Avance Importe:\n              "
                                  ),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.datosEstadisticas
                                          .gradoAvancePorcentajeImportePremios
                                      )
                                    }
                                  })
                                ])
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 12, md: 9 } },
                    [
                      _c(
                        "el-card",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.cargando,
                              expression: "cargando"
                            }
                          ],
                          attrs: { "body-style": { padding: "20px" } }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [_c("span", [_vm._v("Ganadores")])]
                          ),
                          _vm.datosEstadisticas
                            ? _c("div", [
                                _c("h4", [
                                  _vm._v(
                                    "\n              En transito:\n              "
                                  ),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.datosEstadisticas
                                          .cantPremiosEnTransito
                                      )
                                    }
                                  })
                                ]),
                                _c("h4", [
                                  _vm._v(
                                    "\n              Entregados:\n              "
                                  ),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.datosEstadisticas
                                          .cantPremiosEntregados
                                      )
                                    }
                                  })
                                ]),
                                _c("h4", [
                                  _vm._v(
                                    "\n              Retornados:\n              "
                                  ),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.datosEstadisticas
                                          .cantPremiosRetornados
                                      )
                                    }
                                  })
                                ]),
                                _c("h4", [
                                  _vm._v(
                                    "\n              Reenviados:\n              "
                                  ),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.datosEstadisticas
                                          .cantPremiosReenviados
                                      )
                                    }
                                  })
                                ])
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _vm.dialogSrc
            ? _c(
                "el-card",
                {
                  attrs: { shadow: "always", "body-style": { padding: "20px" } }
                },
                [
                  _c("p", [
                    _c("b", [_vm._v("Fecha: ")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$common.formatearFecha(_vm.dialogSrc.fecha))
                    )
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("Cantidad de Códigos: ")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.dialogSrc.cantidadCodigosCanjeados) +
                        "\n      "
                    )
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("Cantidad de Participantes: ")]),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.dialogSrc.cantidadParticipantesUnicos) +
                        "\n      "
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c("modal-det-participantes", { ref: "modalDetParticipantes" }),
      _c("modal-det-participantes-diarios", {
        ref: "modalDetParticipantesDiarios"
      }),
      _c("modal-det-participantes-diarios-agrupado-fecha", {
        ref: "modalDetParticipantesDiariosAgrupadoFecha"
      }),
      _c("modal-det-cod-canjeados-diarios", {
        ref: "modalDetCodCanjeadosDiarios"
      }),
      _c("modal-det-cod-canjeados-diarios-agrupado-fecha", {
        ref: "modalDetCodCanjeadosDiariosAgrupadoFecha"
      }),
      _c("modal-det-cod-canjeados-por-provincia", {
        ref: "modalDetCodCanjeadosPorProvincia"
      }),
      _c("modal-det-por-fecha-ganadores", { ref: "modalDetPorFechaGanadores" }),
      _c("modal-det-por-fecha-participantes-canjes", {
        ref: "modalDetPorFechaParticipantesCanjes"
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titulo" }, [_c("h3", [_vm._v("Inicio")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }