var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Participantes Únicos (por DNI)",
            confirmarClose: false
          }
        },
        [
          _c("h6", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v("Total participantes (únicos): "),
            _c("b", [_vm._v(_vm._s(_vm.totalParticipantes))])
          ]),
          _c("br"),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                plain: "",
                loading: _vm.cargandoDescarga
              },
              on: {
                click: function($event) {
                  return _vm.descargarArchivo()
                }
              }
            },
            [_vm._v("\n      Descargar Excel\n    ")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cargando,
                  expression: "cargando"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.displayData }
            },
            [
              _c("el-table-column", { attrs: { prop: "dni", label: "DNI" } }),
              _c("el-table-column", {
                attrs: { prop: "nombreCompleto", label: "Nombre" }
              }),
              _c("el-table-column", {
                attrs: { prop: "cantidad", label: "Cantidad Canjes" }
              })
            ],
            1
          ),
          _c("br"),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  "page-size": _vm.pageSize,
                  total: _vm.datos.length
                },
                on: { "current-change": _vm.handleCurrentChange }
              })
            ],
            1
          ),
          _c("br"),
          _c("p", { staticStyle: { "margin-left": "10px" } }, [
            _c("b", [_vm._v("Nota:")]),
            _vm._v(" el "),
            _c("b", [_vm._v("nombre")]),
            _vm._v(
              " aparece únicamente si la persona resultó ganadora (porque registró sus datos)"
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }