<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3>
          <i class="el-icon-postcard"></i>Provincias
        </h3>
      </div>
    </div>
    <el-card :body-style="{ padding: '20px' }">
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column :min-width="100">
            <template slot="header">
              <maca-datatable-buscar label="Nombre" v-model="filtroNombre"></maca-datatable-buscar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.nombre"></span>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "provincia",
  components: {},
  data() {
    return {
      urlTabla: this.$api.URL + "/provincia/obtenerTodos",
      paramsTabla: ["paisID=1"],
      actualizarTabla: true,
      bloquearTabla: true,

      filtroNombre: null
    };
  },
  methods: {},
  watch: {
    filtroNombre() {
      if (this.filtroNombre != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "nombre",
            this.filtroNombre
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "nombre");
      }

      this.actualizarTabla = true;
    },
  }
};
</script>

