<template>
  <div>
    <maca-modal titulo="Nuevo Estado" :impedirClose="impedirClose" :confirmar-close="true" ref="modal">
      <el-form :model="form" ref="form" :rules="formRules" label-width="150px" v-loading="cargando">
        <el-form-item label="Estado" prop="estado">
          <maca-select-box v-model="form.estado" :url="urlEstado"></maca-select-box>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "nuevo-estado",
  data() {
    return {
      form: {
        estado: ""
      },
      impedirClose: false,
      cargando: false,
      id: 0,

      urlEstado: this.$api.URL + "/estadoCanje/obtenerTodosSelect",

      formRules: {
        estado: [
          {
            required: true,
            message: "Por favor introduzca el estado.",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$eventStrings._actualizarTabla);
          this.$refs.modal.confirmarClose = false
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        canjeID: this.id,
        estadoCanjeID: this.form.estado.id
      };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/canje/actualizarEstado",
        params,
        this.$usuarioToken()
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Estado actualizado con éxito!",
          type: "success"
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      }
      //

      return false;
    }
  }
};
</script>
