var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.datos && !_vm.vacio
      ? _c(
          "div",
          [
            _c("doughnut", {
              staticStyle: { height: "500px" },
              attrs: {
                labels: _vm.labels,
                data: _vm.dataset,
                colors: _vm.colors
              }
            })
          ],
          1
        )
      : _c("div", { staticStyle: { display: "flex", height: "50px" } }, [
          _c("span", { staticStyle: { margin: "auto" } }, [
            _vm._v("No hay provincias con códigos.")
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }