<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3>
          <i class="el-icon-date"></i>Calendario
        </h3>
      </div>
      <div class="botones">
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          @click="$refs.modalNuevo.abrir()"
        >Nueva Fecha</el-button>
      </div>
    </div>
    <el-card :body-style="{ padding: '20px' }">
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column :min-width="160">
            <template slot="header">
              <maca-datatable-buscar-select
                  label="Promoción"
                  v-model="filtroPromocion"
                  :urlSelect="urlPromocion"
              ></maca-datatable-buscar-select>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.promocion"></span>
            </template>
          </el-table-column>
          <el-table-column label="Fecha Desde">
            <template slot-scope="props">
              <span v-text="$common.formatearFecha(props.row.fechaDesde)"></span>
            </template>
          </el-table-column>
          <el-table-column label="Fecha Hasta">
            <template slot-scope="props">
              <span v-text="$common.formatearFecha(props.row.fechaHasta)"></span>
            </template>
          </el-table-column>
          <el-table-column label="Hora Desde">
            <template slot-scope="props">
              <span v-text="props.row.horaDesde"></span>
            </template>
          </el-table-column>
          <el-table-column label="Hora Hasta">
            <template slot-scope="props">
              <span v-text="props.row.horaHasta"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="100">
            <template slot="header">
              <maca-datatable-buscar-select
                  label="Provincia"
                  v-model="filtroProvincia"
                  :urlSelect="urlProvincia"
              ></maca-datatable-buscar-select>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.provincia"></span>
            </template>
          </el-table-column>
          <el-table-column label="Localidad">
            <template slot-scope="props">
              <span v-text="props.row.localidad"></span>
            </template>
          </el-table-column>
          <el-table-column label="Cant. Total">
            <template slot-scope="props">
              <span v-text="props.row.cantidadPremioTotal"></span>
            </template>
          </el-table-column>
          <el-table-column label="Cant. Canjeada">
            <template slot-scope="props">
              <span v-text="props.row.cantidadPremioCanjeado"></span>
            </template>
          </el-table-column>
          <el-table-column label="Cant. Disp.">
            <template slot-scope="props">
              <span v-text="props.row.cantidadPremioRestante" style="color: green"></span>
            </template>
          </el-table-column>
          <el-table-column label="Premios Agotados">
            <template slot-scope="props">
              <b
                v-if="props.row.cantidadPremioRestante == 0"
                style="color: green"
              >Si</b>
              <span v-else>No</span>
            </template>
          </el-table-column>
          <el-table-column label="Ver" width="70">
            <template slot-scope="props">
              <el-button
                  type="info"
                  size="small"
                  plain
                  @click="$refs.modalVer.abrir(props.row)"
                  round
              >
                <i class="el-icon-zoom-in"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                v-if="props.row.cantidadPremioCanjeado == 0"
                type="default"
                size="small"
                @click="$refs.modalModificar.abrir(props.row.id)"
                round
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button v-if="props.row.cantidadPremioCanjeado == 0"
                  type="danger" size="small" @click="eliminar(props.row.id)" plain round>
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo ref="modalNuevo" @actualizar-tabla="actualizarTabla = true"></modal-nuevo>
    <modal-modificar ref="modalModificar" @actualizar-tabla="actualizarTabla = true"></modal-modificar>
    <modal-ver ref="modalVer" @actualizar-tabla="actualizarTabla = true"></modal-ver>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";
import ModalVer from "./modales/ver";

export default {
  name: "calendario",
  components: {
    ModalNuevo,
    ModalModificar,
    ModalVer
  },
  data() {
    return {
      urlTabla: this.$api.URL + "/calendario/obtenerTodos",
      paramsTabla: [],
      actualizarTabla: true,
      bloquearTabla: true,

      filtroNombre: null,
      filtroPromocion: null,
      filtroProvincia: null,
      filtroEstadoCanje: null,

      urlPromocion: this.$api.URL + "/promocion/obtenerTodosSelect",
      urlProvincia: this.$api.URL + "/provincia/obtenerTodos?paisID=1"
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar la fecha."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { calendarioID: id };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/calendario/eliminar",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Fecha borrada con éxito!",
          type: "success"
        });
      } else if (respuestaApi.estado == -3) {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      }
      //
    }
  },
  watch: {
    filtroPromocion() {
      if (this.filtroPromocion != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "promocionID",
            this.filtroPromocion.id
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "promocionID");
      }

      this.actualizarTabla = true;
    },
    filtroProvincia() {
      if (this.filtroProvincia != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "provinciaID",
            this.filtroProvincia.id
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "provinciaID");
      }

      this.actualizarTabla = true;
    }
  }
};
</script>

