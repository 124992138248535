<template>
  <div>
    <maca-modal
      titulo="Asignar Nro. seguimiento"
      :impedirClose="impedirClose"
      :confirmar-close="true"
      ref="modal"
    >
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        label-width="150px"
        v-loading="cargando"
      >
        <el-form-item label="Numero" prop="codigoSeguimiento">
          <el-input
            placeholder="Por favor ingrese el numero"
            v-model="form.codigoSeguimiento"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar
            :onSubmit="onSubmit"
            texto="Guardar"
          ></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>
  
  <script>
export default {
  name: "asignar-cliente",
  data() {
    return {
      form: {
        codigoSeguimiento: null,
      },
      impedirClose: false,
      cargando: false,
      canjeID: 0,

      formRules: {
        codigoSeguimiento: [
          {
            required: true,
            message: "Por favor ingrese un numero de seguimiento.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir(id, nro) {
      this.$refs.modal.abrir(nro);

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.form.codigoSeguimiento = nro;

      this.canjeID = id;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }
        console.log("paso");
        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$eventStrings._actualizarTabla);
          this.$refs.modal.confirmarClose = false;
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        canjeID: this.canjeID,
        codigoSeguimiento: this.form.codigoSeguimiento,
      };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/canje/actualizarCodigoSeguimiento",
        params,
        this.$usuarioToken()
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "Nro. seguimiento asignado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
};
</script>
  