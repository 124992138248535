<template>
  <div>
    <el-date-picker
      v-model="fechaSeleccionada"
      type="date"
      format="dd/MM/yyyy"
      value-format="dd/MM/yyyy"
      :placeholder="placeholder"
      :picker-options="fechaOpciones"
      :disabled="disabled"
    ></el-date-picker>
    <el-time-select
      v-if="stepTimePicker != '00:1'"
      v-model="horaSeleccionada"
      placeholder="Hora"
      format="HH:mm"
      value-format="HH:mm"
      :picker-options="{
        start: '00:00',
        step: stepTimePicker,
        end: '23:59'
      }"
      :disabled="fechaSeleccionada == '' || disabled"
    ></el-time-select>
    <el-time-picker
      v-else
      v-model="horaSeleccionada"
      placeholder="Hora"
      format="HH:mm"
      value-format="HH:mm"
      :disabled="fechaSeleccionada == '' || disabled"
    ></el-time-picker>
  </div>
</template>

<script>
export default {
  name: "maca-fecha-hora",
  props: {
    placeholder: {
      type: String,
      default: "Día"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    pasadoHabilitado: {
      type: Boolean,
      default: true
    },
    futuroHabilitado: {
      type: Boolean,
      default: true
    },
    diasSemana: {
      type: Array, // ["lu", "ma", "mi", "ju", "vi", "sá", "do"]
      default: null
    },
    diasDeshabilitados: {
      type: Array,
      default: null
    },
    frecuenciaMinutos: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      fechaSeleccionada: "",
      horaSeleccionada: "",

      shortcuts: [
        {
          text: "Hoy",
          onClick: picker => {
            picker.$emit("pick", this.$moment(new Date()).format("DD/MM/YYYY"));
          }
        },
        {
          text: "Mañana",
          onClick: picker => {
            picker.$emit(
              "pick",
              this.$moment(new Date())
                .add(1, "day")
                .format("DD/MM/YYYY")
            );
          }
        },
        {
          text: "La semana que viene",
          onClick: picker => {
            picker.$emit(
              "pick",
              this.$moment(new Date())
                .add(1, "week")
                .format("DD/MM/YYYY")
            );
          }
        }
      ]
    };
  },
  computed: {
    stepTimePicker() {
      let step_hora = "00";
      let step_minuto = this.frecuenciaMinutos + "";

      return step_hora + ":" + step_minuto;
    },
    fechaOpciones() {
      let opciones = {};

      if (
        this.diasSemana != null &&
        this.diasDeshabilitados != null &&
        !this.futuroHabilitado
      ) {
        opciones.shortcuts = this.shortcuts;
      }

      opciones.disabledDate = this.diaDeshabilitado;

      return opciones;
    }
  },
  methods: {
    diaDeshabilitado(time) {
      let deshabilitado = false;

      if (!this.pasadoHabilitado) {
        deshabilitado = time.getTime() < Date.now() - 3600 * 1000 * 24;
      }
      if (!this.futuroHabilitado) {
        deshabilitado = time.getTime() > Date.now();
      }
      if (this.diasSemana != null) {
        let timeEnDia = false;
        this.diasSemana.forEach(dia => {
          if (this.$moment(time.getTime()).format("dd") == dia) {
            timeEnDia = true;
          }
        });
        deshabilitado = !timeEnDia;
      }
      if (this.diasDeshabilitados != null) {
        this.diasDeshabilitados.forEach(diaDes => {
          if (time.getTime() == this.$moment(diaDes)) {
            deshabilitado = true;
          }
        });
      }

      return deshabilitado;
    },

    seleccionar(fecha, hora) {
      this.fechaSeleccionada = fecha;
      this.horaSeleccionada = hora;
    }
  },
  watch: {
    horaSeleccionada: {
      handler: function() {
        this.$emit("update:fecha", this.fechaSeleccionada);
        this.$emit("update:hora", this.horaSeleccionada);
      }
    }
  }
};
</script>
