<template>
  <div>
    <maca-modal titulo="Ganadores por fecha" ref="modal" :confirmarClose="false">
      <el-form style="margin-left: 10px; margin-right: 10px;">
        <el-form-item label="Rango de Fechas">
          <maca-input-rango-fecha style="width: 400px;" v-model="rangoFecha" @input="obtenerDatos()"></maca-input-rango-fecha>
        </el-form-item>
      </el-form>

      <h6 style="margin-left: 10px;">Total de ganadores: <b>{{ totalGanadores }}</b></h6>

      <el-table
          :data="displayData"
          style="width: 100%"
          v-loading="cargando">
        <el-table-column
            sortable>
          <template slot="header" slot-scope="props">
            <div @click="sort('fecha')">Fecha</div>
          </template>
          <template slot-scope="props">{{ $common.formatearFecha(props.row.fecha) }}</template>
        </el-table-column>
        <el-table-column
            prop="cantidadGanadores"
            label="Cantidad">
          <template slot-scope="props">
            {{ props.row.cantidadGanadores }}
          </template>
        </el-table-column>
      </el-table>
      <br>
      <div style="text-align: center">
        <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :total="datos.length">
        </el-pagination>
      </div>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "detPorFechaGanadores",
  data() {
    return {
      datos: [],
      promocionID: 0,
      totalGanadores: 0,
      page: 1,
      pageSize: 10,
      currentSort: 'fecha',
      currentSortDir: 'asc',
      cargando: false,
      rangoFecha: null,
    };
  },
  methods: {
    abrir(promocionID) {
      this.promocionID = promocionID
      this.page = 1
      this.datos = []
      this.totalGanadores = 0
      this.rangoFecha = null
      this.$refs.modal.abrir()

      this.obtenerDatos()
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    async obtenerDatos() {
      this.cargando = true

      let urlParams = "?promocionID=" + this.promocionID
      if (this.rangoFecha != null) {
        urlParams =
            urlParams + "&fechaDesde=" + this.rangoFecha[0]
        urlParams =
            urlParams + "&fechaHasta=" + this.rangoFecha[1]
      }

      let respuestaApi = await this.$api.get(this.$api.URL + "/canje/obtenerDatosEstadisticaPorFechaGanadores"
          + urlParams,
          this.$usuarioToken());

      this.cargando = false

      if (respuestaApi != null) {
        this.datos = respuestaApi[0].detallePorFecha != null ? respuestaApi[0].detallePorFecha : []
        this.totalGanadores = respuestaApi[0].cantidadGanadores != null ? respuestaApi[0].cantidadGanadores : 0
        return true;

      } else {
        this.$message({
          message: "Error al obtener datos",
          type: "error",
          showClose: true,
          duration: 0
        });
      }

      return false;
    }
  },
  computed: {
    displayData:function() {
      return this.datos.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.page-1)*this.pageSize;
        let end = this.page*this.pageSize;
        if(index >= start && index < end) return true;
      });
    }
  },
};
</script>
