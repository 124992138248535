let moment = require("moment");
moment.locale("es");

// Agregar parametro con valor value al array proporcionado: ["param=value"]
// si ya existe, sobrescribir
function agregarUrlParam(array, param, value) {
  let yaAnadido = false;

  for (let index = 0; index < array.length; index++) {
    const element = array[index];

    if (element.split("=", 1)[0] == param) {
      yaAnadido = true;
      array[index] = param + "=" + value;
    }
  }

  if (!yaAnadido) {
    array.push(param + "=" + value);
  }
}

// Saca un parametro del arreglo con el nombre proporcionado
function sacarUrlParam(array, param) {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];

    if (element.split("=", 1)[0] == param) {
      array.splice(index, 1);
    }
  }
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFecha(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("DD/MM/YYYY");
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY hh:mm
function formatearFechaHora(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);

  if (!date.isValid()) {
    return "";
  }

  return moment(fecha).format("DD/MM/YYYY hh:mm");
}

// Dada una hora de formato hh:mm:ss, devolver hh:mm
function formatearHora(hora) {
  if (hora == null) {
    return;
  }
  return hora.substr(0, 5);
}

// Activar animacion en eventos click
function toggleAnimation(evento, clase) {
  // Agregar clase al elemento. Si la clase ya estaba, toggle devuelve false
  // y la elimina
  let anim = evento.target.classList.toggle(clase);

  // si la clase ya estaba, agregarla otra vez para volver a reproducir la anim
  // esperar un poco hasta que vue termine de ejecutar la instruccion anterior
  if (!anim) {
    setTimeout(() => {
      anim = evento.target.classList.toggle(clase);
    }, 50);
  }
}

export default {
  agregarUrlParam,
  sacarUrlParam,
  formatearFecha,
  formatearFechaHora,
  formatearHora,
  toggleAnimation
};
