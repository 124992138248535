<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3>Inicio</h3>
      </div>
      <div class="botones">
        <maca-select-box
          style="width: 250px"
          icon="el-icon-star-off"
          :placeholder="
            noHayPromocionVigente && !promocionVigente
              ? 'Promoción'
              : 'Cargando...'
          "
          v-model="promocionVigente"
          :url="urlPromocion"
          :disabled="cargando"
        ></maca-select-box>
      </div>
    </div>

    <div
      v-if="noHayPromocionVigente && !promocionVigente"
      style="height: 200px; display: flex"
    >
      <span style="margin: auto"
        >No hay una promoción vigente. Seleccione una manualmente.</span
      >
    </div>
    <div v-else>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="12" :md="6">
          <el-card :body-style="{ padding: '20px' }" v-loading="cargando">
            <div slot="header">
              <span>Participantes por DNI</span>
              <el-button
                type="primary"
                size="small"
                plain
                @click="
                  $refs.modalDetParticipantes.abrir(
                    promocionVigente.id,
                    datosEstadisticas.cantParticipantesUnicosTotales
                  )
                "
              >
                Ver detalle
              </el-button>
            </div>
            <div v-if="datosEstadisticas">
              <h4>
                Totales:
                <span
                  v-text="datosEstadisticas.cantParticipantesUnicosTotales"
                  style="font-weight: bold"
                ></span>
                <td
                  v-if="datosEstadisticas.cantParticipantesUnicosTotales > 0"
                ></td>
              </h4>
              <br />
              <table class="tabla-info">
                <tbody>
                  <tr
                    v-for="(
                      item, index
                    ) in datosEstadisticas.cantParticipantesDiarios"
                    :key="index"
                  >
                    <th v-text="item.diaNombre"></th>
                    <td
                      v-text="item.cantidadParticipantes"
                      style="font-weight: bold"
                    ></td>
                    <td v-if="item.cantidadParticipantes > 0">
                      <el-button
                        type="primary"
                        size="small"
                        plain
                        @click="
                          $refs.modalDetParticipantesDiariosAgrupadoFecha.abrir(
                            promocionVigente.id,
                            item.diaNro,
                            item.diaNombre,
                            item.cantidadParticipantes
                          )
                        "
                        round
                      >
                        <i class="el-icon-zoom-in"></i>
                      </el-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <small
                  >Los <b>totales para cada día</b> pueden diferir del
                  <b>total de participantes únicos</b> ya que una persona puede
                  haber participado más de una vez en diferentes días de la
                  semana</small
                >
              </p>
            </div>
          </el-card>
        </el-col>
        <!-- <el-col :xs="24" :sm="12" :md="9">
          <el-card :body-style="{ padding: '20px' }" v-loading="cargando">
            <div slot="header">
              <span>Ganadores por Comercial</span>
            </div>
            <ganadores-comercial :datos="datosEstadisticas"></ganadores-comercial>
          </el-card>
        </el-col> -->
        <el-col :xs="24" :sm="12" :md="9">
          <el-card :body-style="{ padding: '20px' }" v-loading="cargando">
            <div slot="header">
              <span>Calendario</span>
            </div>
            <vc-date-picker
              v-model="fechaSeleccionada"
              disable-page-swipe
              is-expanded
              @input="dayPick"
            >
            </vc-date-picker>
          </el-card>
        </el-col>

        <el-col :xs="24" :sm="12" :md="9">
          <el-card :body-style="{ padding: '20px' }" v-loading="cargando">
            <div slot="header">
              <span>Códigos Por Provincia</span>
              <el-button
                type="primary"
                size="small"
                plain
                @click="
                  $refs.modalDetCodCanjeadosPorProvincia.abrir(
                    promocionVigente.id,
                    datosEstadisticas.cantCodigosTotales
                  )
                "
              >
                Ver detalle
              </el-button>
            </div>
            <codigos-provincia :datos="datosEstadisticas"></codigos-provincia>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :xs="24" :sm="12" :md="6">
          <el-card :body-style="{ padding: '20px' }" v-loading="cargando">
            <div slot="header">
              <span>Códigos Canjeados</span>
              <el-button
                type="primary"
                size="small"
                plain
                @click="
                  $refs.modalDetPorFechaParticipantesCanjes.abrir(
                    promocionVigente.id
                  )
                "
              >
                Ver detalle
              </el-button>
            </div>
            <div v-if="datosEstadisticas">
              <h4>
                Totales:
                <span
                  v-text="datosEstadisticas.cantCodigosUnicosTotales"
                  style="font-weight: bold"
                ></span>
              </h4>
              <table class="tabla-info">
                <tbody>
                  <tr
                    v-for="(
                      item, index
                    ) in datosEstadisticas.cantCodigosDiarios"
                    :key="index"
                  >
                    <th v-text="item.diaNombre"></th>
                    <td
                      v-text="item.cantidadCodigos"
                      style="font-weight: bold"
                    ></td>
                    <td v-if="item.cantidadCodigos > 0">
                      <el-button
                        type="primary"
                        size="small"
                        plain
                        @click="
                          $refs.modalDetCodCanjeadosDiariosAgrupadoFecha.abrir(
                            promocionVigente.id,
                            item.diaNro,
                            item.diaNombre,
                            item.cantidadCodigos
                          )
                        "
                        round
                      >
                        <i class="el-icon-zoom-in"></i>
                      </el-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-card>
        </el-col>

        <el-col :xs="24" :sm="12" :md="9">
          <el-card :body-style="{ padding: '20px' }" v-loading="cargando">
            <div slot="header">
              <span>Premios/Ganadores</span>
              <el-button
                type="primary"
                size="small"
                plain
                @click="
                  $refs.modalDetPorFechaGanadores.abrir(promocionVigente.id)
                "
              >
                Ver detalle
              </el-button>
            </div>
            <div v-if="datosEstadisticas">
              <h4>
                Salidos:
                <span
                  v-text="datosEstadisticas.cantPremiosTotalCanjeada"
                ></span>
              </h4>
              <h4>
                % Salidos:
                <span
                  v-if="datosEstadisticas.cantPremiosTotalCanjeada > 0"
                  v-text="
                    parseFloat(
                      datosEstadisticas.cantPremiosTotal /
                        datosEstadisticas.cantPremiosTotalCanjeada
                    ).toFixed(2) + '%'
                  "
                ></span>
                <span v-else>0</span>
              </h4>
              <br />
              <h4>
                Importe Salidos:
                <span
                  v-text="datosEstadisticas.importePremiosTotalCanjeado"
                ></span>
              </h4>
              <h4>
                % Importe Salidos:
                <span
                  v-if="datosEstadisticas.cantPremiosTotalCanjeada > 0"
                  v-text="
                    parseFloat(
                      datosEstadisticas.importePremiosTotal /
                        datosEstadisticas.importePremiosTotalCanjeado
                    ).toFixed(2) + '%'
                  "
                ></span>
                <span v-else>0</span>
              </h4>
              <br />
              <h4>
                Grado Avance Salidos:
                <span
                  v-text="
                    datosEstadisticas.gradoAvancePorcentajeCantidadPremios
                  "
                ></span>
              </h4>
              <h4>
                Grado Avance Importe:
                <span
                  v-text="datosEstadisticas.gradoAvancePorcentajeImportePremios"
                ></span>
              </h4>
            </div>
          </el-card>
        </el-col>

        <el-col :xs="24" :sm="12" :md="9">
          <el-card :body-style="{ padding: '20px' }" v-loading="cargando">
            <div slot="header">
              <span>Ganadores</span>
            </div>
            <div v-if="datosEstadisticas">
              <h4>
                En transito:
                <span v-text="datosEstadisticas.cantPremiosEnTransito"></span>
              </h4>
              <h4>
                Entregados:
                <span v-text="datosEstadisticas.cantPremiosEntregados"></span>
              </h4>
              <h4>
                Retornados:
                <span v-text="datosEstadisticas.cantPremiosRetornados"></span>
              </h4>
              <h4>
                Reenviados:
                <span v-text="datosEstadisticas.cantPremiosReenviados"></span>
              </h4>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <el-card
        v-if="dialogSrc"
        shadow="always"
        :body-style="{ padding: '20px' }"
      >
        <!-- card body -->
        <p><b>Fecha: </b> {{ $common.formatearFecha(dialogSrc.fecha) }}</p>
        <p>
          <b>Cantidad de Códigos: </b> {{ dialogSrc.cantidadCodigosCanjeados }}
        </p>
        <p>
          <b>Cantidad de Participantes: </b>
          {{ dialogSrc.cantidadParticipantesUnicos }}
        </p>
      </el-card>
    </el-dialog>
    <modal-det-participantes
      ref="modalDetParticipantes"
    ></modal-det-participantes>
    <modal-det-participantes-diarios
      ref="modalDetParticipantesDiarios"
    ></modal-det-participantes-diarios>
    <modal-det-participantes-diarios-agrupado-fecha
      ref="modalDetParticipantesDiariosAgrupadoFecha"
    ></modal-det-participantes-diarios-agrupado-fecha>
    <modal-det-cod-canjeados-diarios
      ref="modalDetCodCanjeadosDiarios"
    ></modal-det-cod-canjeados-diarios>
    <modal-det-cod-canjeados-diarios-agrupado-fecha
      ref="modalDetCodCanjeadosDiariosAgrupadoFecha"
    ></modal-det-cod-canjeados-diarios-agrupado-fecha>
    <modal-det-cod-canjeados-por-provincia
      ref="modalDetCodCanjeadosPorProvincia"
    ></modal-det-cod-canjeados-por-provincia>
    <modal-det-por-fecha-ganadores
      ref="modalDetPorFechaGanadores"
    ></modal-det-por-fecha-ganadores>
    <modal-det-por-fecha-participantes-canjes
      ref="modalDetPorFechaParticipantesCanjes"
    ></modal-det-por-fecha-participantes-canjes>
  </div>
</template>

<style></style>

<style scoped></style>

<script>
import GanadoresComercial from "./ganadores_comercial";
import CodigosProvincia from "./codigos_provincia";
import ModalDetParticipantes from "./modales/det_participantes";
import ModalDetParticipantesDiarios from "./modales/det_participantes_diarios";
import ModalDetParticipantesDiariosAgrupadoFecha from "./modales/det_participantes_diarios_agrupado_fecha";
import ModalDetCodCanjeadosDiarios from "./modales/det_cod_canjeados_diarios";
import ModalDetCodCanjeadosDiariosAgrupadoFecha from "./modales/det_cod_canjeados_diarios_agrupado_fecha";
import ModalDetCodCanjeadosPorProvincia from "./modales/det_cod_canjeados_por_provincia";
import ModalDetPorFechaGanadores from "./modales/det_por_fecha_ganadores";
import ModalDetPorFechaParticipantesCanjes from "./modales/det_por_fecha_participantes_canjes";

import moment from "moment";

export default {
  name: "home",
  components: {
    GanadoresComercial,
    CodigosProvincia,
    ModalDetParticipantes,
    ModalDetParticipantesDiarios,
    ModalDetParticipantesDiariosAgrupadoFecha,
    ModalDetCodCanjeadosDiarios,
    ModalDetCodCanjeadosDiariosAgrupadoFecha,
    ModalDetCodCanjeadosPorProvincia,
    ModalDetPorFechaGanadores,
    ModalDetPorFechaParticipantesCanjes,
  },
  data() {
    return {
      fechaSeleccionada: null,

      dialogVisible: false,
      dialogSrc: null,

      datosPromocion: null,
      datosEstadisticas: null,
      promocionVigente: null,

      urlPromocion: this.$api.URL + "/promocion/obtenerTodosSelect",
      cargando: false,
      noHayPromocionVigente: false,
    };
  },

  async created() {
    await this.getPromocionVigente();
    this.getEstadisticas();
  },
  methods: {
    // Función para convertir el formato de fecha
    convertirFecha(fechaOriginal) {
      // Parsea la fecha original utilizando Moment.js
      const fechaParseada = moment(fechaOriginal);

      // Formatea la fecha en el nuevo formato "YYYY-MM-dd"
      const fechaFormateada = fechaParseada.format("YYYY-MM-DD");

      return fechaFormateada;
    },
    async dayPick(value) {
      if (!value) {
        return;
      }
      let desde = this.convertirFecha(value);
      let hasta = this.convertirFecha(value);
      this.cargando = true;

      const respuestaApi = await this.$api.get(
        this.$api.URL +
          `/canje/obtenerDatosEstadisticaPorFechaParticipantesCanjes?promocionID=${this.promocionVigente.id}&fechaDesde=${desde}&fechaHasta=${hasta}`,
        this.$usuarioToken(),
        0
      );

      this.cargando = false;
      if (respuestaApi) {
        if (
          respuestaApi[0].detallePorFecha &&
          respuestaApi[0].detallePorFecha.length > 0
        ) {
          this.dialogSrc = respuestaApi[0].detallePorFecha[0];
          this.dialogVisible = true;
        } else {
          this.$message({
            message: "¡No se encontraron datos para esta fecha!",
            type: "error",
          });
        }
      }
    },
    async getPromocionVigente() {
      this.cargando = true;

      this.datosPromocion = await this.$api.get(
        this.$api.URL + "/promocion/obtenerTodosSelect?vigente=1",
        this.$usuarioToken(),
        0
      );

      if (this.datosPromocion == null || this.datosPromocion.length == 0) {
        return;
      }

      this.promocionVigente = this.datosPromocion[0];

      //this.cargando = false;
    },
    async getEstadisticas() {
      this.cargando = true;

      if (this.promocionVigente == null) {
        this.cargando = false;
        this.noHayPromocionVigente = true;
        return;
      }

      this.datosEstadisticas = await this.$api.get(
        this.$api.URL +
          "/canje/obtenerDatosEstadistica?promocionID=" +
          this.promocionVigente.id,
        this.$usuarioToken(),
        0
      );

      this.cargando = false;
    },
  },
  watch: {
    promocionVigente() {
      if (!this.cargando) {
        this.getEstadisticas();
      }
    },
  },
};
</script>
<style>
.is-selected {
  color: #1989fa;
}
</style>
