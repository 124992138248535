import errores from "./errores_genericos"

export default class HandlerRespuesta {
  constructor(response, params) {
    if (response !== null) {
      // sacar data de response
      this.estado = response.data.estado
      this.mensaje = response.data.mensaje
      this.excepcion = response.data.excepcion
      this.datos = response.data.datos
      this.url = response.request.responseURL

      // Si params es un objeto, obtener datos del objeto. Puede ser un FormData
      // o un diccionario
      // Este diccionario sólo se usa al mostrar dialogo de error desconocido
      if (typeof params == "object") {
        if (Object.keys(params).length > 0) {
          // params es un diccionario normal
          this.params = params
        } else {
          // *es posible* que params sea un FormData. Tratar de convertir a diccionario
          // sino dejar this.params como nulo
          try {
            this.params = {}
            for (let par of params.entries()) {
              this.params[par[0]] = par[1]
            }
          } catch (error) {}
        }
      }
      //

      // procesar error legible
      this.errorUsuario = ""
      if (this.estado === null) {
        this.errorUsuario = errores.CARGARDATOS
        return true
      }
      if (this.estado !== 1) {
        this.errorUsuario = errores.CARGARDATOS
        return true
      }
    }
  }

  hayErrores() {
    if (this.errorUsuario != "") {
      return true
    }

    return false
  }

  getError() {
    return "Mensaje: " + this.mensaje + " | Excepcion: " + this.excepcion
  }
}
