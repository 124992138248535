<template>
  <div class="mi-sidebar">
    <div class="sidebar-header">
      <div style="height: 100%; display: flex">
        <img
          style="margin: auto; margin-left: 20px; max-width: 200px"
          src="../../../assets/logo-secco-blanco.png"
          alt="Logo Secco"
          height="50px"
        />
      </div>
      <el-popover
        style="
          margin: auto;
          margin-left: 0px;
          margin-bottom: 5px;
          margin-right: 0px;
        "
        ref="popover"
        placement="right"
        trigger="click"
      >
        <div>
          <span v-if="$usuario()" v-text="$usuario().email"></span>
          <br />
          <span
            v-for="(item, index) in $usuarioRoles()"
            :key="index"
            v-text="item.nombre"
            style="display: block"
          ></span>
        </div>
        <el-button
          type="text"
          style="color: white; width: 100%"
          slot="reference"
        >
          <div style="display: flex">
            <span
              v-if="$usuario()"
              style="margin-left: 20px; text-align: left"
              v-text="$usuario().nombre + ' ' + this.$usuario().apellido"
            ></span>
            <i
              style="margin: auto; margin-right: 20px"
              class="el-icon-caret-bottom"
            ></i>
          </div>
        </el-button>
      </el-popover>
    </div>
    <el-menu
      class="sidebar-menu"
      background-color="transparent"
      :default-active="itemMenuActivo"
      :default-openeds="menuItemsAbiertos"
    >
      <el-menu-item @click="$router.push('/')" index="home">
        <i class="el-icon-s-home"></i>
        <span>Inicio</span>
      </el-menu-item>
      <el-menu-item @click="$router.push('/premios')" index="premios">
        <i class="el-icon-goods"></i>
        <span>Premios</span>
      </el-menu-item>
      <el-menu-item
        @click="$router.push('/participantes')"
        index="participantes"
      >
        <i class="el-icon-user"></i>
        <span>Participantes</span>
      </el-menu-item>
      <el-menu-item @click="$router.push('/ganadores')" index="ganadores">
        <i class="el-icon-medal"></i>
        <span>Ganadores</span>
      </el-menu-item>
      <el-menu-item @click="$router.push('/calendario')" index="calendario">
        <i class="el-icon-date"></i>
        <span>Calendario</span>
      </el-menu-item>

      <el-submenu index="reportes" v-if="$usuarioEs.superadmin">
        <template slot="title">
          <i class="el-icon-tickets"></i>
          <span>Reportes</span>
        </template>

        <el-menu-item
          index="reportes-ganadores"
          @click="$emit('reporteGanadores')"
        >
          <i class="el-icon-medal"></i>
          <span>Ganadores</span>
        </el-menu-item>

        <el-menu-item
          index="reportes-participantes"
          @click="$emit('reporteParticipantes')"
        >
          <i class="el-icon-medal"></i>
          <span>Participantes</span>
        </el-menu-item>

        <!--<el-menu-item index="estadisticas">
          <i class="el-icon-data-line"></i>
          <span>Estadísticas</span>
        </el-menu-item>-->
      </el-submenu>

      <el-submenu index="ajustes" v-if="$usuarioEs.superadmin">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span>Ajustes</span>
        </template>
        <el-menu-item
          @click="$router.push('/ajustes/provincias')"
          index="provincias"
        >
          <i class="el-icon-postcard"></i>
          <span>Provincias</span>
        </el-menu-item>
        <el-menu-item
          @click="$router.push('/ajustes/localidades')"
          index="localidades"
        >
          <i class="el-icon-postcard"></i>
          <span>Localidades</span>
        </el-menu-item>
        <!-- <el-menu-item
          @click="$router.push('/ajustes/comerciales')"
          index="comerciales"
        >
          <i class="el-icon-postcard"></i>
          <span>Comerciales</span>
        </el-menu-item> -->
        <el-menu-item
          @click="$router.push('/ajustes/tipos_premio')"
          index="tipospremios"
        >
          <i class="el-icon-goods"></i>
          <span>Tipos de Premio</span>
        </el-menu-item>
        <el-menu-item
          @click="$router.push('/ajustes/promocion_vigente')"
          index="promocionvigente"
        >
          <i class="el-icon-star-off"></i>
          <span>Promoción Vigente</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
    <div class="sidebar-footer">
      <small class="copyright">&copy; {{ anioActual }}</small>
      <a href="http://macamedia.com.ar" target="_blank"> MACAMEDIA</a>
      <br />
      <small class="version">
        <b>Versión:</b>
        <span>{{ " 1.0.0" }}</span>
      </small>
    </div>
  </div>
</template>

<style scoped>
.mi-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-header {
  height: 150px;
  min-height: 150px;
  background-color: #ec2127;
  display: flex;
  flex-direction: column;
}

.sidebar-menu {
  overflow: auto;
}

.el-menu {
  border-right: solid 0px !important;
}

.sidebar-footer {
  border-top: solid 1px #4f5b62;
  height: 70px;
  padding: 10px;
  margin: auto;
  margin-bottom: 0px;
  color: black;
}
</style>

<script>
import { AUTH_LOGOUT } from "../../../store/actions/auth";

export default {
  name: "sidebar",
  data() {
    return {
      itemMenuActivo: "home",
      menuItemsAbiertos: [],
      empresa: {},
      anioActual: new Date().getFullYear(),
    };
  },
  computed: {},
  created: function () {
    // Resolver si se debe obtener datos
    // llamar a API solo si el usuario esta autenticado (tiene token)
    if (this.$store.getters.isAuthenticated) {
      // this.getEmpresa().then(datos => (this.empresa = datos));

      // Corregir item de menu seleccionado al hacer refresh de pagina
      this.resolverMenuHighlight(this.$router.currentRoute);
    }

    this.$router.afterEach((to) => {
      // Si no hay logo de empresa, traerlo
      if (this.$store.getters.isAuthenticated && !this.empresa.imagenLogo) {
        // this.getEmpresa().then(datos => (this.empresa = datos));
      }

      // Resolver highlight del menu
      this.resolverMenuHighlight(to);
    });
  },
  methods: {
    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/"));
      this.$router.replace("/login");
    },

    // Cuando se recarga la pagina, colocar como activo el item correcto de la ruta
    resolverMenuHighlight(to) {
      if (to.name == "Home") {
        this.itemMenuActivo = "home";
      } else if (to.name == "Premios") {
        this.itemMenuActivo = "premios";
      } else if (to.name == "Ganadores") {
        this.itemMenuActivo = "ganadores";
      } else if (to.name == "Calendario") {
        this.itemMenuActivo = "calendario";
      } else if (to.name == "AjustesProvincias") {
        this.itemMenuActivo = "provincias";
      } else if (to.name == "AjustesLocalidades") {
        this.itemMenuActivo = "localidades";
      } else if (to.name == "AjustesComerciales") {
        this.itemMenuActivo = "comerciales";
      } else if (to.name == "AjustesTiposPremios") {
        this.itemMenuActivo = "tipospremios";
      } else if (to.name == "AjustesPromocionVigente") {
        this.itemMenuActivo = "promocionvigente";
      }
    },

    getEmpresa() {
      return this.$api.get(
        this.$api.URL + "/empresa/obtenerDatos",
        this.$usuarioToken()
      );
    },
  },
};
</script>
