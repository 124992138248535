import errores from "./errores_genericos"

export default class HandlerError {
  constructor(error) {
    if (error != null) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        this.error = 0
        this.errorUsuario = errores.GENERICO
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // Probablemente timeout, volver a realizar la consulta. También puede ser error 500
        this.error = 1
        this.errorUsuario = errores.SUBIRDATOS
      } else {
        // Something happened in setting up the request that triggered an Error
        this.error = 2
        this.errorUsuario = errores.GENERICO
      }
    }

    this.estado = 0
  }
}
