<template>
  <div>
    <maca-modal titulo="Modificar Premio" :impedirClose="impedirClose" :confirmar-close="true" ref="modal">
      <el-form :model="form" ref="form" :rules="formRules" label-width="150px" v-loading="cargando">
        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre"></el-input>
        </el-form-item>
        <el-form-item label="Precio $" prop="precio">
          <el-input-number v-model="form.precio" controls-position="right" :min="0" :precision="2"></el-input-number>
        </el-form-item>
        <el-form-item label="Stock" prop="stock">
          <el-input-number v-model="form.stock" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="Tipo de Premio" prop="tipo">
          <maca-select-box icon="el-icon-goods" v-model="form.tipo" :url="urlTipoPremio"></maca-select-box>
        </el-form-item>
        <el-form-item label="Promoción" prop="promocion">
          <maca-select-box icon="el-icon-star-off" v-model="form.promocion" :url="urlPromocion"></maca-select-box>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "modificar-premio",
  data() {
    return {
      form: {
        nombre: "",
        precio: "",
        stock: "",
        tipo: null,
        promocion: null
      },
      impedirClose: false,
      cargando: false,
      id: 0,

      urlTipoPremio: this.$api.URL + "/tipoPremio/obtenerTodosSelect",
      urlPromocion: this.$api.URL + "/promocion/obtenerTodosSelect",

      formRules: {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change"
          }
        ],
        precio: [
          {
            required: true,
            message: "Por favor introduzca el precio.",
            trigger: "change"
          }
        ],
        stock: [
          {
            required: true,
            message: "Por favor introduzca el stock.",
            trigger: "change"
          }
        ],
        tipo: [
          {
            required: true,
            message: "Por favor introduzca el tipo.",
            trigger: "change"
          }
        ],
        promocion: [
          {
            required: true,
            message: "Por favor introduzca la promoción.",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$api.get(
        this.$api.URL + "/premio/obtenerDatos?premioID=" + this.id,
        this.$usuarioToken()
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.nombre;
        this.form.precio = respuestaApi.precio;
        this.form.stock = respuestaApi.stockOriginal;
        this.form.tipo = {
          id: respuestaApi.tipoPremioID,
          nombre: respuestaApi.tipoPremio
        };
        this.form.promocion = {
          id: respuestaApi.promocionID,
          nombre: respuestaApi.promocion
        };
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$eventStrings._actualizarTabla);
          this.$refs.modal.confirmarClose = false
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        premioID: this.id,
        promocionID: this.form.promocion.id,
        tipoPremioID: this.form.tipo.id,
        nombre: this.form.nombre,
        precio: this.form.precio,
        stock: this.form.stock
      };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/premio/actualizar",
        params,
        this.$usuarioToken()
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Premio actualizado con éxito!",
          type: "success"
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      }
      //

      return false;
    }
  }
};
</script>
