<template>
  <div :style="appStyle" id="app" style="height: 100%">
    <el-container style="height: 100%">
      <transition name="expand-top-bottom" mode="out-in">
        <el-header
          id="app-header-container"
          height="50px"
          v-show="headerON"
          :class="{ con_sidebar: sidebarON }"
        >
          <navigation @toggle-sidebar="handleToogleSidebar()" />
        </el-header>
      </transition>

      <el-container style="height: 100%">
        <transition name="expand-left-right">
          <el-aside id="app-aside-container" width="250px" v-show="sidebarON">
            <sidebar
                @reporteGanadores="$refs.modalReporteGanadores.abrir()"
                 @reporteParticipantes="$refs.modalReporteParticipantes.abrir()"
            />
          </el-aside>
        </transition>
        <transition name="back-fade">
          <!-- Fondo negro cuando movil = true -->
          <div
            id="sidebar-background"
            v-show="sidebarON && $esMovil()"
            @click="handleToogleSidebar()"
          ></div>
        </transition>
        <!--  main.  -->
        <el-main :class="{ con_navbar: headerON }">
          <!--boton volver en main-->
          <transition name="expand-top-bottom">
            <div style="margin-bottom: 10px" v-if="botonVolverON">
              <el-button
                id="boton-volver"
                type="primary"
                @click="$router.go(-1)"
                icon="el-icon-arrow-left"
                round
              >Volver</el-button>
            </div>
          </transition>

          <!-- Contenido principal. Dar resumen a las vistas que lo necesiten -->
          <transition name="fade-quick" mode="out-in">
            <router-view />
          </transition>
        </el-main>
      </el-container>
    </el-container>
    <reporte-ganadores ref="modalReporteGanadores"></reporte-ganadores>
    <reporte-participantes ref="modalReporteParticipantes"></reporte-participantes>
  </div>
</template>

<style>
@import "../css/fuentes.css";

/* TODO algunos componentes se ven mal si se saca el css de bootstrap*/
@import "../../node_modules/bootstrap/dist/css/bootstrap.css";

@import "../css/app.css";
</style>

<style scoped>
#boton-volver {
  margin-bottom: 20px;
}
.con_navbar {
  margin-top: 50px;
}

.fade-quick-enter-active,
.fade-quick-leave-active {
  transition: opacity 0.2s;
}

.fade-quick-enter,
.fade-quick-leave-to {
  opacity: 0;
}

.expand-top-bottom-enter-active,
.expand-top-bottom-leave-active {
  transition: all 0.2s ease-in-out;
}
.expand-top-bottom-enter-active-to {
  margin-top: 0px;
}
.expand-top-bottom-enter, .expand-top-bottom-leave-to /* .fade-leave-active below version 2.1.8 */ {
  margin-top: -70px;
}

@media (min-device-width: 769px) {
  .expand-left-right-enter-active,
  .expand-left-right-leave-active {
    transition: all 0.2s ease-in-out;
  }
  .expand-left-right-enter-active-to {
    transform: translateX(250px);
    margin-left: 0px;
  }
  .expand-left-right-enter, .expand-left-right-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translateX(-250px);
    margin-left: -250px;
  }
}
@media only screen and (max-device-width: 768px) {
  #boton-volver {
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .expand-left-right-enter-active,
  .expand-left-right-leave-active {
    transition: all 0.2s ease-in-out;
  }
  .expand-left-right-enter-active-to {
    transform: translateX(250px);
  }
  .expand-left-right-enter, .expand-left-right-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translateX(-250px);
  }

  .back-fade-enter-active,
  .back-fade-leave-active {
    transition: opacity 0.1s ease-in-out;
  }
  .back-fade-enter, .back-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>

<script>
import Navigation from "./layout/navigation";
import Sidebar from "./layout/sidebar";
import ReporteGanadores from "./layout/sidebar/modales/ganadores";
import ReporteParticipantes from "./layout/sidebar/modales/participantes";

export default {
  components: {
    Navigation,
    Sidebar,
    ReporteGanadores,
    ReporteParticipantes
  },
  name: "app",
  data() {
    return {
      ocultarSidebar: false,
      headerON: false,
      sidebarON: false,
      botonVolverON: false,

      sidebarkey: 0,

      appStyle: {}
    };
  },
  created: function() {
    // Ocultar sidebar en primera carga si es movil
    if (this.$esMovil()) {
      this.ocultarSidebar = true;
    }

    // Resolver si mostrar u ocultar los siguientes componentes:
    // boton volver, breadcrumb, sidebar, header
    // Esto se realiza en cada recarga de pagina. Además, resolver cuando se cambia de ruta

    this.resolverBotonVolver(this.$router.currentRoute);
    this.resolverLayout();
    this.resolverAppStyle();

    this.$router.afterEach((to, from) => {
      if (this.$esMovil() && this.ocultarSidebar == false) {
        this.ocultarSidebar = true;
      }

      this.resolverBotonVolver(to);
      this.resolverAppStyle();
      this.resolverLayout();
    });
  },
  methods: {
    // Muestra u oculta sidebar dependiendo de su estado
    handleToogleSidebar() {
      if (this.ocultarSidebar) {
        this.ocultarSidebar = false;
      } else {
        this.ocultarSidebar = true;
      }

      this.resolverLayout();
    },

    // Resuelve si mostrar o no el header y el sidebar
    resolverLayout() {
      this.headerON = this.$store.getters.isAuthenticated;
      this.sidebarON = this.$store.getters.isAuthenticated;

      // mostrar si esta logeado el usuario
      if (this.$store.getters.isAuthenticated) {
        if (this.ocultarSidebar) {
          this.sidebarON = false;
        } else {
          this.sidebarON = true;
        }
      }

      // Verificar prop layout (bool)
      try {
        let layoutON = this.$route.matched[0].props.default.layout;
        if (layoutON != null) {
          this.headerON = layoutON;
          this.sidebarON = layoutON;
        }
      } catch (error) {
        // El prop volver no esta seteado
      }
    },

    // Resolver si mostrar o no el boton volver arriba de main
    resolverBotonVolver(route) {
      if (!this.$store.getters.isAuthenticated) {
        this.botonVolverON = false;
        return;
      }

      try {
        this.botonVolverON = route.matched[0].props.default.volver;
      } catch (error) {
        // El prop volver no esta seteado, osea no se usa el boton
        this.botonVolverON = false;
      }
    },

    resolverAppStyle() {
      try {
        this.appStyle = this.$route.matched[0].props.default.style;
      } catch (error) {
        // El prop no esta seteado
        this.appStyle = null;
      }
    }
  }
};
</script>
