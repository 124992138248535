<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: ["labels", "data", "colors"],
  data() {
    return {
      datacollection: {
        labels: [],
        datasets: [
          {
            label: "Dataset 1",
            backgroundColor: [],
            data: []
          }
        ]
      }
    };
  },
  mounted() {
    this.checkearDatos();
  },
  methods: {
    checkearDatos() {
      setTimeout(() => {
        this.datacollection.labels = this.labels;
        this.datacollection.datasets[0].backgroundColor = this.colors;
        this.datacollection.datasets[0].data = this.data;

        if (this.datacollection.labels.length == 0) {
          this.checkearDatos();
        } else {
          this.renderChart(this.datacollection, {
            responsive: true,
            maintainAspectRatio: false
          });
        }
      }, 250);
    }
  }
};
</script>
