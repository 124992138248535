<template>
  <div>
    <maca-modal titulo="Modificar Comercial" :impedirClose="impedirClose" :confirmar-close="true" ref="modal">
      <el-form :model="form" ref="form" :rules="formRules" label-width="150px" v-loading="cargando">
        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre"></el-input>
        </el-form-item>
        <el-form-item label="Descripción" prop="descripcion">
          <el-input v-model="form.descripcion"></el-input>
        </el-form-item>
        <el-form-item label="Capital" prop="capital">
          <el-switch
            v-model="form.capital"
            active-text="Si"
            inactive-text="No"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Interior" prop="interior">
          <el-switch
            v-model="form.interior"
            active-text="Si"
            inactive-text="No"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Provincia" prop="provincia">
          <maca-select-box icon="el-icon-postcard" v-model="form.provincia" :url="urlProvincia"></maca-select-box>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "modificar-comercial",
  data() {
    return {
      form: {
        nombre: "",
        descripcion: "",
        capital: 0,
        interior: 0,
        provincia: null
      },
      impedirClose: false,
      cargando: false,
      id: 0,

      urlProvincia: this.$api.URL + "/provincia/obtenerTodos?paisID=1",

      formRules: {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change"
          }
        ],
        provincia: [
          {
            required: true,
            message: "Por favor introduzca la provincia.",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$api.get(
        this.$api.URL + "/comercial/obtenerDatos?comercialID=" + this.id,
        this.$usuarioToken()
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.nombre;
        this.form.descripcion = respuestaApi.descripcion;
        this.form.capital = respuestaApi.capital;
        this.form.interior = respuestaApi.interior;
        this.form.provincia = {
          id: respuestaApi.provinciaID,
          nombre: respuestaApi.provincia
        };
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$eventStrings._actualizarTabla);
          this.$refs.modal.confirmarClose = false
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        comercialID: this.id,
        nombre: this.form.nombre,
        descripcion: this.form.descripcion,
        capital: this.form.capital,
        interior: this.form.interior,
        provinciaID: this.form.provincia.id
      };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/comercial/actualizar",
        params,
        this.$usuarioToken()
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Comercial actualizado con éxito!",
          type: "success"
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      }
      //

      return false;
    }
  }
};
</script>
