var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _c(
        "el-select",
        {
          ref: "elSelect",
          attrs: {
            remote: "",
            "reserve-keyword": "",
            "remote-method": _vm.remoteMethod,
            loading: _vm.loading,
            filterable: _vm.permitirInput,
            placeholder: _vm.placeholder,
            clearable: _vm.clearable,
            size: _vm.size,
            disabled: _vm.disabled,
            multiple: ""
          },
          on: {
            change: function($event) {
              return _vm.input($event)
            },
            clear: function($event) {
              return _vm.$emit("opcion-seleccionada", null)
            }
          },
          model: {
            value: _vm.seleccionado,
            callback: function($$v) {
              _vm.seleccionado = $$v
            },
            expression: "seleccionado"
          }
        },
        _vm._l(_vm.opciones, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value }
          })
        }),
        1
      ),
      _c("div", { staticClass: "slot-wrapper" }, [_vm._t("append")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }