var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Modificar Premio",
            impedirClose: _vm.impedirClose,
            "confirmar-close": true
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cargando,
                  expression: "cargando"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Nombre", prop: "nombre" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.nombre,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "nombre", $$v)
                      },
                      expression: "form.nombre"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Precio $", prop: "precio" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      "controls-position": "right",
                      min: 0,
                      precision: 2
                    },
                    model: {
                      value: _vm.form.precio,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "precio", $$v)
                      },
                      expression: "form.precio"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Stock", prop: "stock" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1 },
                    model: {
                      value: _vm.form.stock,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "stock", $$v)
                      },
                      expression: "form.stock"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Tipo de Premio", prop: "tipo" } },
                [
                  _c("maca-select-box", {
                    attrs: { icon: "el-icon-goods", url: _vm.urlTipoPremio },
                    model: {
                      value: _vm.form.tipo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tipo", $$v)
                      },
                      expression: "form.tipo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Promoción", prop: "promocion" } },
                [
                  _c("maca-select-box", {
                    attrs: { icon: "el-icon-star-off", url: _vm.urlPromocion },
                    model: {
                      value: _vm.form.promocion,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "promocion", $$v)
                      },
                      expression: "form.promocion"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("maca-boton-guardar", {
                    attrs: { onSubmit: _vm.onSubmit }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }