<template>
  <div>
    <div v-if="datos && !vacio">
      <doughnut style="height: 500px" :labels="labels" :data="dataset" :colors="colors"></doughnut>
    </div>
    <div v-else style="display: flex; height: 50px">
      <span style="margin: auto">No hay provincias con códigos.</span>
    </div>
  </div>
</template>

<script>
import Doughnut from "../../components/charts/doughnut";

export default {
  props: ["datos"],
  components: { Doughnut },
  data() {
    return {
      labels: [],
      colors: [],
      dataset: [],
      vacio: false
    };
  },
  methods: {
    getRandomColor() {
      var letters = '0123456789ABCDEF'.split('');
      var color = '#';
      for (var i = 0; i < 6; i++ ) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
  watch: {
    datos() {
      this.datos.cantCodigosPorProvinciaLocalidad.forEach(estadistica => {
        this.labels.push(estadistica.provincia);
      });
      this.datos.cantCodigosPorProvinciaLocalidad.forEach(estadistica => {
        this.dataset.push(estadistica.cantidad);
      });
      this.datos.cantCodigosPorProvinciaLocalidad.forEach(estadistica => {
        this.colors.push(this.getRandomColor());
      });

      let total = 0;
      this.dataset.forEach(data => {
        total += data;
      });

      this.vacio = total == 0;
    }
  }
};
</script>