<template>
  <div>
    <maca-modal titulo="Participantes por Día" ref="modal" :confirmarClose="false">
      <h6 style="margin-left: 10px;">Día <b>{{ diaSemana }}</b> - Total participantes (únicos): <b>{{ totalParticipantes }}</b></h6>

      <el-table
          :data="displayData"
          style="width: 100%"
          v-loading="cargando">
        <el-table-column
            prop="fecha"
            label="Fecha">
          <template slot-scope="props">
            {{ $common.formatearFechaHora(props.row.fecha) }}
          </template>
        </el-table-column>
        <el-table-column
            prop="dni"
            label="DNI">
        </el-table-column>
        <el-table-column
            prop="ganador"
            label="Ganó">
          <template slot-scope="props">
            <span v-if="props.row.ganador"><b>SI</b></span>
            <span v-else>NO</span>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <div style="text-align: center">
        <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :total="datos.length">
        </el-pagination>
      </div>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "detParticipantesDiarios",
  data() {
    return {
      datos: [],
      promocionID: 0,
      diaNro: 0,
      diaSemana: '',
      totalParticipantes: 0,
      page: 1,
      pageSize: 10,
      cargando: false,
    };
  },
  methods: {
    abrir(promocionID, diaNro, diaSemana, totalParticipantes) {
      this.promocionID = promocionID
      this.diaNro = diaNro
      this.diaSemana = diaSemana
      this.totalParticipantes = totalParticipantes
      this.page = 1
      this.datos = []
      this.$refs.modal.abrir()

      this.obtenerDatos()
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    async obtenerDatos() {
      this.cargando = true

      let urlParams = "?promocionID=" + this.promocionID
          + "&diaNro=" + this.diaNro

      let respuestaApi = await this.$api.get(this.$api.URL + "/canje/obtenerDatosEstadisticaPorDiaParticipante"
          + urlParams,
          this.$usuarioToken());

      this.cargando = false

      if (respuestaApi != null) {
        this.datos = respuestaApi[0].detallePorFecha != null ? respuestaApi[0].detallePorFecha : []
        return true;

      } else {
        this.$message({
          message: "Error al obtener datos",
          type: "error",
          showClose: true,
          duration: 0
        });
      }

      return false;
    }
  },
  computed: {
    displayData() {
      if (!this.datos || this.datos.length === 0) return [];

      return this.datos.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
    }
  },
};
</script>
