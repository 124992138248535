<template>
  <div>
    <maca-modal titulo="Participantes Únicos (por DNI)" ref="modal" :confirmarClose="false">
      <h6 style="margin-left: 10px;">Total participantes (únicos): <b>{{ totalParticipantes }}</b></h6>

      <br>
      <el-button
          type="primary"
          size="small"
          plain
          :loading="cargandoDescarga"
          @click="descargarArchivo()"
      >
        Descargar Excel
      </el-button>

      <el-table
          :data="displayData"
          style="width: 100%"
          v-loading="cargando">
        <el-table-column
            prop="dni"
            label="DNI">
        </el-table-column>
        <el-table-column
            prop="nombreCompleto"
            label="Nombre">
        </el-table-column>
        <el-table-column
            prop="cantidad"
            label="Cantidad Canjes">
        </el-table-column>
      </el-table>
      <br>
      <div style="text-align: center">
        <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :total="datos.length">
        </el-pagination>
      </div>
      <br>
      <p style="margin-left: 10px;"><b>Nota:</b> el <b>nombre</b> aparece únicamente si la persona resultó ganadora (porque registró sus datos)</p>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "detParticipantes",
  data() {
    return {
      datos: [],
      promocionID: 0,
      totalParticipantes: 0,
      page: 1,
      pageSize: 10,
      cargando: false,
      cargandoDescarga: false,
    };
  },
  methods: {
    abrir(promocionID, totalParticipantes) {
      this.promocionID = promocionID
      this.totalParticipantes = totalParticipantes
      this.page = 1
      this.datos = []
      this.$refs.modal.abrir()

      this.obtenerDatos()
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    async obtenerDatos() {
      this.cargando = true

      let urlParams = "?promocionID=" + this.promocionID

      let respuestaApi = await this.$api.get(this.$api.URL + "/canje/obtenerDatosEstadisticaPorParticipante"
          + urlParams,
          this.$usuarioToken());

      this.cargando = false

      if (respuestaApi != null) {
        this.datos = respuestaApi
        return true;

      } else {
        this.$message({
          message: "Error al obtener datos",
          type: "error",
          showClose: true,
          duration: 0
        });
      }

      return false;
    },
    async descargarArchivo() {
      if (this.cargandoDescarga) {
        return
      }

      this.cargandoDescarga = true

      let token = this.$usuarioToken()

      let respuestaApi = await this.$api.downloadFile(
          this.$api.URL + '/canje/obtenerExcelEstadisticaPorParticipante?promocionID=' + this.promocionID,
          token,
          "estadisticaPorParticipante.xlsx")

      this.cargandoDescarga = false
    },
  },
  computed: {
    displayData() {
      if (!this.datos || this.datos.length === 0) return [];

      return this.datos.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
    }
  },
};
</script>
