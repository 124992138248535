<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3>
          <i class="el-icon-postcard"></i>Localidades
        </h3>
      </div>
      <div class="botones">
        <el-button
            type="primary"
            icon="el-icon-plus"
            plain
            @click="$refs.modalNuevo.abrir()"
        >Nueva Localidad</el-button>
      </div>
    </div>
    <el-card :body-style="{ padding: '20px' }">
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column :min-width="100">
            <template slot="header">
              <maca-datatable-buscar label="Nombre" v-model="filtroNombre"></maca-datatable-buscar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.nombre"></span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <maca-datatable-buscar-select
                label="Provincia"
                v-model="filtroProvincia"
                :urlSelect="urlProvincia"
              ></maca-datatable-buscar-select>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.provincia"></span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <maca-datatable-buscar label="Código Postal" v-model="filtroCodPostal"></maca-datatable-buscar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.codigoPostal"></span>
            </template>
          </el-table-column>
          <el-table-column label="Código Área">
            <template slot-scope="props">
              <span v-text="props.row.codigoArea"></span>
            </template>
          </el-table-column>
          <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                  type="default"
                  size="small"
                  @click="$refs.modalModificar.abrir(props.row.id)"
                  round
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo ref="modalNuevo" @actualizar-tabla="actualizarTabla = true"></modal-nuevo>
    <modal-modificar ref="modalModificar" @actualizar-tabla="actualizarTabla = true"></modal-modificar>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "localidad",
  components: {
    ModalNuevo,
    ModalModificar
  },
  data() {
    return {
      urlTabla: this.$api.URL + "/localidad/obtenerTodos",
      paramsTabla: ["provinciaID=1"],
      actualizarTabla: true,
      bloquearTabla: true,

      filtroProvincia: null,
      filtroNombre: null,
      filtroCodPostal: null,

      urlProvincia: this.$api.URL + "/provincia/obtenerTodos?paisID=1"
    };
  },
  methods: {},
  watch: {
    filtroProvincia() {
      if (this.filtroProvincia != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "provinciaID",
          this.filtroProvincia.id
        );
      } else {
        this.$common.agregarUrlParam(this.paramsTabla, "provinciaID", 1);
      }

      this.actualizarTabla = true;
    },
    filtroNombre() {
      if (this.filtroNombre != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "nombre",
            this.filtroNombre
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "nombre");
      }

      this.actualizarTabla = true;
    },
    filtroCodPostal() {
      if (this.filtroCodPostal != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "codPostal",
            this.filtroCodPostal
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "codPostal");
      }

      this.actualizarTabla = true;
    },
  }
};
</script>

