var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: { titulo: "Códigos Canjeados por Día", confirmarClose: false }
        },
        [
          _c("h6", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v("Día "),
            _c("b", [_vm._v(_vm._s(_vm.diaSemana))]),
            _vm._v(" - Total códigos: "),
            _c("b", [_vm._v(_vm._s(_vm.totalCodigos))])
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cargando,
                  expression: "cargando"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.displayData }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "fecha", label: "Fecha" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$common.formatearFechaHora(props.row.fecha)
                            ) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "codigo", label: "Código" }
              }),
              _c("el-table-column", {
                attrs: { prop: "ganador", label: "Ganador" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        props.row.ganador
                          ? _c("span", [_c("b", [_vm._v("SI")])])
                          : _c("span", [_vm._v("NO")])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("br"),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  "page-size": _vm.pageSize,
                  total: _vm.datos.length
                },
                on: { "current-change": _vm.handleCurrentChange }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }