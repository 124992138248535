var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Modificar Fecha",
            impedirClose: _vm.impedirClose,
            "confirmar-close": true
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cargando,
                  expression: "cargando"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Rango de Fechas", prop: "rangoFecha" } },
                [
                  _c("maca-input-rango-fecha", {
                    model: {
                      value: _vm.form.rangoFecha,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "rangoFecha", $$v)
                      },
                      expression: "form.rangoFecha"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Rango de Horas", prop: "rangoHora" } },
                [
                  _c("input-rango-hora", {
                    model: {
                      value: _vm.form.rangoHora,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "rangoHora", $$v)
                      },
                      expression: "form.rangoHora"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Provincia", prop: "provincia" } },
                [
                  _c("maca-select-box", {
                    attrs: { icon: "el-icon-postcard", url: _vm.urlProvincia },
                    on: {
                      "opcion-seleccionada": function(item) {
                        return _vm.provinciaCambiada(item)
                      }
                    },
                    model: {
                      value: _vm.form.provincia,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "provincia", $$v)
                      },
                      expression: "form.provincia"
                    }
                  })
                ],
                1
              ),
              _vm.form.provincia != null
                ? _c(
                    "div",
                    { key: _vm.keyLocalidad },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Localidad", prop: "localidad" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { "margin-top": "1px" },
                              attrs: {
                                placeholder: "Localidad",
                                url: _vm.urlLocalidad
                              },
                              model: {
                                value: _vm.form.localidad,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "localidad", $$v)
                                },
                                expression: "form.localidad"
                              }
                            },
                            _vm._l(_vm.listaLocalidad, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.nombre, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "Tipos de Premios", prop: "tipoPremio" } },
                [
                  _c("maca-select-box", {
                    attrs: { icon: "el-icon-goods", url: _vm.urlTipoPremio },
                    on: {
                      "opcion-seleccionada": function(datos) {
                        return _vm.agregarTipoPremio(datos)
                      }
                    },
                    model: {
                      value: _vm.form.tipoPremio,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tipoPremio", $$v)
                      },
                      expression: "form.tipoPremio"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Cantidades" } },
                _vm._l(_vm.tiposPremio, function(item, index) {
                  return _c("div", { key: index }, [
                    item.accion != "B"
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "margin-bottom": "5px"
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: item.nombre,
                                callback: function($$v) {
                                  _vm.$set(item, "nombre", $$v)
                                },
                                expression: "item.nombre"
                              }
                            }),
                            _c("el-input-number", {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { "controls-position": "right", min: 1 },
                              model: {
                                value: item.cantidadPremio,
                                callback: function($$v) {
                                  _vm.$set(item, "cantidadPremio", $$v)
                                },
                                expression: "item.cantidadPremio"
                              }
                            }),
                            _c("el-button", {
                              staticStyle: { "margin-left": "10px" },
                              attrs: {
                                type: "default",
                                icon: "el-icon-close",
                                circle: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.sacarTipoPremio(item)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                }),
                0
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Modo" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      on: {
                        change: function($event) {
                          _vm.form.valorModo = 1
                        }
                      },
                      model: {
                        value: _vm.form.modo,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "modo", $$v)
                        },
                        expression: "form.modo"
                      }
                    },
                    [_vm._v("Salida por Códigos")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      on: {
                        change: function($event) {
                          _vm.form.valorModo = 1
                        }
                      },
                      model: {
                        value: _vm.form.modo,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "modo", $$v)
                        },
                        expression: "form.modo"
                      }
                    },
                    [_vm._v("Salida por Minutos")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "Configuración", prop: "configuracionModo" }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          "controls-position": "right",
                          min: 1,
                          max: _vm.form.valorModo
                        },
                        model: {
                          value: _vm.form.valorPremioModo,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "valorPremioModo", $$v)
                          },
                          expression: "form.valorPremioModo"
                        }
                      }),
                      _c("el-input-number", {
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "controls-position": "right",
                          min: _vm.form.valorPremioModo
                        },
                        model: {
                          value: _vm.form.valorModo,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "valorModo", $$v)
                          },
                          expression: "form.valorModo"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.form.modo == 0
                    ? _c("p", [
                        _vm._v(
                          "\n          Saldrá " +
                            _vm._s(_vm.form.valorPremioModo) +
                            " premio" +
                            _vm._s(_vm.form.valorPremioModo > 1 ? "s" : "") +
                            "\n          cada " +
                            _vm._s(_vm.form.valorModo) +
                            " códigos canjeados.\n        "
                        )
                      ])
                    : _c("p", [
                        _vm._v(
                          "\n          Saldrá " +
                            _vm._s(_vm.form.valorPremioModo) +
                            " premio" +
                            _vm._s(_vm.form.valorPremioModo > 1 ? "s" : "") +
                            "\n          cada " +
                            _vm._s(_vm.form.valorModo) +
                            " minutos.\n        "
                        )
                      ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Promoción", prop: "promocion" } },
                [
                  _c("maca-select-box", {
                    ref: "selectPromocion",
                    attrs: {
                      icon: "el-icon-star-off",
                      url: _vm.urlPromocion,
                      disabled: ""
                    },
                    model: {
                      value: _vm.form.promocion,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "promocion", $$v)
                      },
                      expression: "form.promocion"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("maca-boton-guardar", {
                    attrs: { onSubmit: _vm.onSubmit }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }