<template>
  <div>
    <maca-modal titulo="Ganadores" :confirmar-close="false" ref="modal">
      <el-form :model="form" ref="form" :rules="formRules" label-width="150px" v-loading="cargando">
        <!--<el-form-item label="Rango de Fechas">
          <maca-input-rango-fecha v-model="form.rangoFecha"></maca-input-rango-fecha>
        </el-form-item>-->
        <el-form-item label="Promoción" prop="promocion">
          <maca-select-box icon="el-icon-star-off" v-model="form.promocion" :url="urlPromocion"></maca-select-box>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="descargarExcel" texto="Descargar Excel"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<style scoped>
.card-header {
  display: flex;
}
</style>

<script>
export default {
  name: "modal-reporte-ganadores",
  data() {
    return {
      form: {
        rangoFecha: null,
        promocion: null
      },
      impedirClose: false,
      cargando: false,

      urlPromocion: this.$api.URL + "/promocion/obtenerTodosSelect",

      formRules: {
        promocion: [
          {
            required: true,
            message: "Por favor seleccione la promoción.",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async descargarExcel(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          this.cargando = false;
          this.impedirClose = false;
          afterSubmit();
          return false;
        }

        let url = this.$api.URL + "/reporte/obtenerExcelGanadores?promocionID=" + this.form.promocion.id;
        let separador = "&";

        if (this.form.rangoFecha != null) {
          url = url + separador + "fechaDesde=" + this.form.filtroFecha[0];
          separador = "&";
          url = url + separador + "fechaHasta=" + this.form.filtroFecha[1];
          separador = "&";
        }

        this.cargandoExcel = true;

        this.cargando = true;
        this.impedirClose = true;

        this.$message({
          message: "Procesando datos. Por favor espere hasta obtener el Excel.",
          type: "info",
          icon: "el-icon-info",
          showClose: true,
          duration: 30000,
        });

        let respuestaApi = await this.$api.downloadFileGral(url, this.$usuarioToken(), "reporteGanadores.xlsx");

        this.cargandoExcel = false;

        this.cargando = false;
        this.impedirClose = false;
        afterSubmit();
      });
    },
  }
};
</script>