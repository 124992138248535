var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my_navigation" }, [
    _c("div", { staticStyle: { display: "flex" } }, [
      _vm.$esMovil()
        ? _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "el-button",
                {
                  class: { "boton-menu": true },
                  staticStyle: { margin: "auto" },
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("toggle-sidebar")
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-menu" })]
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.$esMovil()
        ? _c(
            "div",
            { staticStyle: { "margin-left": "17px", display: "flex" } },
            [
              _c(
                "el-button",
                {
                  class: { "boton-menu": true },
                  staticStyle: { margin: "auto" },
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("toggle-sidebar")
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "el-icon-menu",
                    staticStyle: { "font-size": "23px" }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticStyle: { margin: "auto", "margin-right": "20px" } }, [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            !_vm.$esMovil()
              ? _c(
                  "el-button",
                  {
                    class: { "boton-menu": true },
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.logout()
                      }
                    }
                  },
                  [_vm._v("Cerrar Sesión")]
                )
              : _vm._e(),
            _vm.$esMovil()
              ? _c(
                  "el-popover",
                  {
                    ref: "popover",
                    attrs: { placement: "bottom", trigger: "click" }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "menu-mas-boton",
                            attrs: { type: "text", size: "small" },
                            on: { click: _vm.logout }
                          },
                          [_c("span", [_vm._v("Cerrar Sesión")])]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        class: { "boton-menu": true },
                        staticStyle: { "margin-left": "5px" },
                        attrs: {
                          slot: "reference",
                          type: "text",
                          icon: "el-icon-s-tools"
                        },
                        slot: "reference"
                      },
                      [_c("i", { staticClass: "el-icon-s-tools" })]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }