import Vue from "vue"

import router from "../router"
import store from "../store"
import Roles from "./roles"

import common from "./common.js"
import eventStrings from "./event_string.js"
import api from "./api"

Vue.prototype.$common = common
Vue.prototype.$eventStrings = eventStrings
Vue.prototype.$api = api

// Cancelar peticiones cada vez que se cambie de ruta
router.beforeEach((to, from, next) => {
  api.doCancelStack()
  next()
})
//

function checkRol(nombre) {
  let rolesUsuario = []
  rolesUsuario = store.getters.roles

  let check = false

  try {
    check = rolesUsuario.find(function(element) {
      return element.nombre.toLowerCase() == nombre
    })
  } catch (error) {
    return false
  }

  return check
}

Vue.prototype.$usuarioEs = {
  superadmin: () => checkRol(Roles.SUPERADMINISTRADOR),
  operador: () => checkRol(Roles.OPERADOR),
  cliente: () => checkRol(Roles.CLIENTE)
}

// Datos de usuario
Vue.prototype.$usuarioToken = () => {
  return store.getters.token
}
Vue.prototype.$usuarioRoles = () => {
  return store.getters.roles
}
Vue.prototype.$usuario = () => {
  return store.getters.usuario
}
