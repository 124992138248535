<template>
  <div>
    <maca-modal titulo="Nueva Fecha" :impedirClose="impedirClose" :confirmar-close="true" ref="modal">
      <el-form :model="form" ref="form" :rules="formRules" label-width="150px">
        <el-form-item label="Rango de Fechas" prop="rangoFecha">
          <maca-input-rango-fecha v-model="form.rangoFecha"></maca-input-rango-fecha>
        </el-form-item>
        <el-form-item label="Rango de Horas" prop="rangoHora">
          <input-rango-hora v-model="form.rangoHora"></input-rango-hora>
        </el-form-item>
        <el-form-item label="Provincia" prop="provincia">
          <maca-select-box icon="el-icon-postcard" v-model="form.provincia" :url="urlProvincia"
                           @opcion-seleccionada="item => provinciaCambiada(item)"></maca-select-box>
        </el-form-item>
        <div v-if="form.provincia != null" :key="keyLocalidad">
          <el-form-item label="Localidad" prop="localidad">
            <el-select
                style="margin-top: 1px"
                v-model="form.localidad"
                placeholder="Localidad"
                :url="urlLocalidad">
              <el-option
                  v-for="item in listaLocalidad"
                  :key="item.id"
                  :label="item.nombre"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="Tipos de Premios" prop="tipoPremio">
          <maca-select-box
            icon="el-icon-goods"
            v-model="form.tipoPremio"
            @opcion-seleccionada="datos => agregarTipoPremio(datos)"
            :url="urlTipoPremio"
          ></maca-select-box>
        </el-form-item>
        <el-form-item label="Cantidades">
          <div v-for="(item, index) in tiposPremio" :key="index">
            <div style="display: flex; margin-bottom: 5px">
              <el-input v-model="item.nombre" disabled></el-input>
              <el-input-number
                v-model="item.cantidadPremio"
                controls-position="right"
                :min="1"
                style="margin-left: 10px"
              ></el-input-number>
              <el-button
                type="default"
                @click="sacarTipoPremio(item)"
                icon="el-icon-close"
                style="margin-left: 10px"
                circle
              ></el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Modo">
          <el-radio v-model="form.modo" :label="0" @change="form.valorModo = 1">Salida por Códigos</el-radio>
          <el-radio v-model="form.modo" :label="1" @change="form.valorModo = 1">Salida por Minutos</el-radio>
        </el-form-item>
        <el-form-item label="Configuración" prop="configuracionModo">
          <div style="display: flex;">
            <el-input-number
              v-model="form.valorPremioModo"
              controls-position="right"
              :min="1"
              :max="form.valorModo"
            ></el-input-number>
            <el-input-number
              style="margin-left: 10px"
              v-model="form.valorModo"
              controls-position="right"
              :min="form.valorPremioModo"
            ></el-input-number>
          </div>
          <p
            v-if="form.modo === 0"
          >Saldrá {{form.valorPremioModo}} premio{{form.valorPremioModo > 1 ? 's' : ''}} cada {{form.valorModo}} códigos canjeados.</p>
          <p
            v-else
          >Saldrá {{form.valorPremioModo}} premio{{form.valorPremioModo > 1 ? 's' : ''}} cada {{form.valorModo}} minutos.</p>
        </el-form-item>
        <el-form-item label="Promoción" prop="promocion">
          <maca-select-box
            icon="el-icon-star-off"
            placeholder="Cargando..."
            v-model="form.promocion"
            :url="urlPromocion"
            seleccionarPrimero
            disabled
          ></maca-select-box>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
import InputRangoHora from "./input_rango_hora";

export default {
  name: "nuevo-calendario",
  components: { InputRangoHora },
  data() {
    return {
      form: {
        provincia: null,
        localidad: null,
        rangoFecha: null,
        rangoHora: null,
        tipoPremio: null,
        promocion: null,
        modo: 0,
        valorModo: 10,
        valorPremioModo: 1
      },
      impedirClose: false,
      tiposPremio: [],

      keyLocalidad: 0,
      listaLocalidad: null,

      urlProvincia: this.$api.URL + "/provincia/obtenerTodos?paisID=1",
      urlLocalidad: this.$api.URL + "/localidad/obtenerTodos?provinciaID=1",
      urlTipoPremio: this.$api.URL + "/tipoPremio/obtenerTodosSelect",
      urlPromocion: this.$api.URL + "/promocion/obtenerTodosSelect?vigente=1",

      formRules: {
        rangoFecha: [
          {
            required: true,
            message: "Por favor introduzca el rango de fechas.",
            trigger: "change"
          }
        ],
        rangoHora: [
          {
            required: true,
            message: "Por favor introduzca el rango de hora.",
            trigger: "change"
          }
        ],
        provincia: [
          {
            required: true,
            message: "Por favor introduzca la provincia.",
            trigger: "change"
          }
        ],
        localidad: [
          {
            required: true,
            message: "Por favor introduzca la localidad.",
            trigger: "change"
          }
        ],
        tipoPremio: [
          {
            required: true,
            message: "Por favor introduzca al menos un tipo de premio.",
            trigger: "change"
          }
        ],
        promocion: [
          {
            required: true,
            message: "Por favor espere a que termine de cargar la promoción.",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.tiposPremio = [];
        this.$refs.form.resetFields();
      }
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    agregarTipoPremio(datos) {
      let existe = this.tiposPremio.find(function(element) {
        return element.tipoPremioID == datos.id;
      });

      if (existe === undefined) {
        this.tiposPremio.push({
          calendarioDetalleID: 0,
          tipoPremioID: datos.id,
          nombre: datos.nombre,
          cantidadPremio: 1,
          accion: "A"
        });
      }
    },
    sacarTipoPremio(datos) {
      let index = this.tiposPremio.findIndex(function(element) {
        return element.tipoPremioID == datos.tipoPremioID;
      });

      this.tiposPremio.splice(index, 1);
    },
    async provinciaCambiada(item) {
      this.listaLocalidad = [];
      this.form.localidad = null;

      let respuestaApi = await this.$api.get(
          this.$api.URL +
          "/localidad/obtenerTodos?provinciaID=" +
          item.id,
          this.$usuarioToken()
      );

      respuestaApi.forEach((localidad) => {
        let existe = false;

        this.listaLocalidad.forEach((localEnArray) => {
          if (localEnArray.id == localidad.id) {
            existe = true;
          }
        });

        if (!existe) {
          this.listaLocalidad.push(localidad);
        }
      });
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$eventStrings._actualizarTabla);
          this.$refs.modal.confirmarClose = false
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        fechaDesde: this.form.rangoFecha[0],
        fechaHasta: this.form.rangoFecha[1],
        horaDesde: this.form.rangoHora[0],
        horaHasta: this.form.rangoHora[1],
        promocionID: this.form.promocion.id,
        provinciaID: this.form.provincia.id,
        localidadID: this.form.localidad,
        detalleCalendario: JSON.stringify(this.tiposPremio)
      };

      if (this.form.modo === 0) {
        params.salidaPorCodigo = 1;
        params.salidaPorMinuto = 0;
        params.valorSalidaPremio = this.form.valorPremioModo;
        params.valorSalidaCantidad = this.form.valorModo;
        /*params.valorSalidaPorCant =
          this.form.valorModo / this.form.valorPremioModo;*/
      } else {
        params.salidaPorMinuto = 1;
        params.salidaPorCodigo = 0;
        params.valorSalidaPremio = this.form.valorPremioModo;
        params.valorSalidaCantidad = this.form.valorModo;
        /*params.valorSalidaPorMin =
          this.form.valorModo / this.form.valorPremioModo;*/
      }

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/calendario/crear",
        params,
        this.$usuarioToken()
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Calendario creado con éxito!",
          type: "success"
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      }
      //

      return false;
    }
  },
  watch: {
    provincia() {
      this.keyLocalidad += 1;
    },
  },
};
</script>

