function mostrarMensaje(mensaje, apiHandler) {
  const h = this.$createElement

  let messageFinal = h("maca-mensaje-error", {
    props: {
      mensaje: mensaje,
      handler: apiHandler
    }
  })

  this.$message({
    message: messageFinal,
    type: "error",
    iconClass: "-",
    customClass: "mensajeError",
    showClose: false,
    duration: 0
  })
}

export default mostrarMensaje
