var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("el-card", { attrs: { "body-style": { padding: "20px" } } }, [
        _c(
          "div",
          [
            _c(
              "maca-datatable",
              {
                attrs: {
                  url: _vm.urlTabla,
                  params: _vm.paramsTabla,
                  actualizar: _vm.actualizarTabla,
                  bloquear: _vm.bloquearTabla
                },
                on: {
                  "update:actualizar": function($event) {
                    _vm.actualizarTabla = $event
                  },
                  "update:bloquear": function($event) {
                    _vm.bloquearTabla = $event
                  }
                }
              },
              [
                _c(
                  "el-table-column",
                  {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$common.formatearFechaHora(
                                    props.row.fecha
                                  )
                                )
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-rangofecha", {
                          attrs: { label: "Fecha Canje" },
                          model: {
                            value: _vm.filtroFecha,
                            callback: function($$v) {
                              _vm.filtroFecha = $$v
                            },
                            expression: "filtroFecha"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 160 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.promocion)
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-buscar-select", {
                          attrs: {
                            label: "Promoción",
                            urlSelect: _vm.urlPromocion
                          },
                          model: {
                            value: _vm.filtroPromocion,
                            callback: function($$v) {
                              _vm.filtroPromocion = $$v
                            },
                            expression: "filtroPromocion"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 100 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.provincia)
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-buscar-select", {
                          attrs: {
                            label: "Provincia",
                            urlSelect: _vm.urlProvincia
                          },
                          model: {
                            value: _vm.filtroProvincia,
                            callback: function($$v) {
                              _vm.filtroProvincia = $$v
                            },
                            expression: "filtroProvincia"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { label: "Localidad", "min-width": 100 },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(props.row.localidad)
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 80 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.codigoCanjea)
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-buscar", {
                          attrs: { label: "Codigo" },
                          model: {
                            value: _vm.filtroCodigo,
                            callback: function($$v) {
                              _vm.filtroCodigo = $$v
                            },
                            expression: "filtroCodigo"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 105, label: "Cod. Vto." },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(props.row.codigoCanjeaVto) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-buscar", {
                          attrs: { label: "Cod. Vto." },
                          model: {
                            value: _vm.filtroCodigoCanjeaVto,
                            callback: function($$v) {
                              _vm.filtroCodigoCanjeaVto = $$v
                            },
                            expression: "filtroCodigoCanjeaVto"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 105, label: "Cod. Lote" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(props.row.codigoCanjeaLote) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-buscar", {
                          attrs: { label: "Cod. Lote" },
                          model: {
                            value: _vm.filtroCodigoCanjeaLote,
                            callback: function($$v) {
                              _vm.filtroCodigoCanjeaLote = $$v
                            },
                            expression: "filtroCodigoCanjeaLote"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 80 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.clienteDNI)
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-buscar", {
                          attrs: { label: "Dni" },
                          model: {
                            value: _vm.filtroDni,
                            callback: function($$v) {
                              _vm.filtroDni = $$v
                            },
                            expression: "filtroDni"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 120 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.clienteID != null
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      props.row.clienteNombreCompleto
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-buscar", {
                          attrs: { label: "Persona" },
                          model: {
                            value: _vm.filtroNombre,
                            callback: function($$v) {
                              _vm.filtroNombre = $$v
                            },
                            expression: "filtroNombre"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 80 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.telefono)
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-buscar", {
                          attrs: { label: "Telefono" },
                          model: {
                            value: _vm.filtroTelefono,
                            callback: function($$v) {
                              _vm.filtroTelefono = $$v
                            },
                            expression: "filtroTelefono"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { label: "Premio" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: { textContent: _vm._s(props.row.premio) }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 100 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.estadoCanje)
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-buscar-select", {
                          attrs: {
                            label: "Estado",
                            urlSelect: _vm.urlEstadoCanje
                          },
                          model: {
                            value: _vm.filtroEstadoCanje,
                            callback: function($$v) {
                              _vm.filtroEstadoCanje = $$v
                            },
                            expression: "filtroEstadoCanje"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { "min-width": 100, label: "Ganador" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type:
                                  props.row.ganador == 1 ? "success" : "danger"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(props.row.ganador == 1 ? "Si" : "No")
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Ver Estados", width: "75" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          props.row.ganador == 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "info",
                                    size: "small",
                                    plain: "",
                                    round: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.modalHistorial.abrir(
                                        props.row.id
                                      )
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-truck" })]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Nuevo Estado", width: "70" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          props.row.ganador == 1
                            ? _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-add-location",
                                  plain: "",
                                  round: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.modalNuevoEstado.abrir(
                                      props.row.id
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("modal-historial", { ref: "modalHistorial" }),
      _c("modal-nuevo-estado", {
        ref: "modalNuevoEstado",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contenedor-titulo" }, [
      _c("div", { staticClass: "titulo" }, [
        _c("h3", [
          _c("i", { staticClass: "el-icon-user" }),
          _vm._v("Participantes")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }