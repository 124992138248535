var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "padding-left": "0px" } },
    [
      _c("span", { domProps: { textContent: _vm._s(_vm.label) } }),
      _c(
        "el-popover",
        {
          ref: "popover",
          attrs: {
            placement: "bottom",
            trigger: "click",
            "popper-class": "popbuscar"
          },
          model: {
            value: _vm.popoverVisible,
            callback: function($$v) {
              _vm.popoverVisible = $$v
            },
            expression: "popoverVisible"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("el-button", {
                staticStyle: { width: "40px" },
                attrs: { type: "text", icon: "el-icon-delete", size: "small" },
                on: {
                  click: function($event) {
                    return _vm.limpiar()
                  }
                }
              }),
              _c("el-input", {
                attrs: { size: "small", placeholder: _vm.label },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.guardarValor()
                  }
                },
                model: {
                  value: _vm.buscarInput,
                  callback: function($$v) {
                    _vm.buscarInput = $$v
                  },
                  expression: "buscarInput"
                }
              }),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c("el-button", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      type: "primary",
                      icon: "el-icon-check",
                      size: "small",
                      plain: "",
                      circle: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.guardarValor()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.currentValue == null || _vm.currentValue == ""
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "5px", width: "25px" },
                  attrs: { slot: "reference", type: "text", size: "small" },
                  slot: "reference"
                },
                [_c("span", [_c("i", { staticClass: "el-icon-search" })])]
              )
            : _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "5px" },
                  attrs: {
                    slot: "reference",
                    type: "primary",
                    circle: "",
                    size: "mini"
                  },
                  slot: "reference"
                },
                [_c("span", [_c("i", { staticClass: "el-icon-search" })])]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }