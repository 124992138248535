<template>
  <div style="display: flex">
    <maca-select-box
      ref="pais"
      @opcion-seleccionada="
        datos => {
          paisSeleccionado = datos;
          $refs.provincia.recargar();
        }
      "
      :url="urlPais"
      :necesitaParams="false"
      placeholder="País"
      style="margin-right: 10px"
      :disabled="disabled"
    ></maca-select-box>
    <maca-select-box
      icon="el-icon-postcard"
      @opcion-seleccionada="
        datos => {
          provinciaSeleccionada = datos;
          $refs.localidad.recargar();
        }
      "
      :url="urlProvincia"
      :necesitaParams="true"
      placeholder="Provincia"
      :getParams="
        query => {
          return 'paisID=' + paisSeleccionado.id;
        }
      "
      style="margin-right: 10px"
      :disabled="disabled"
    ></maca-select-box>
    <maca-select-box
      ref="localidad"
      @opcion-seleccionada="
        datos => {
          localidadSeleccionada = datos;
          $emit('update:valor', datos);
        }
      "
      :url="urlLocalidad"
      :necesitaParams="true"
      placeholder="Localidad"
      :getParams="
        query => {
          return 'provinciaID=' + provinciaSeleccionada.id;
        }
      "
      :disabled="disabled"
    ></maca-select-box>
  </div>
</template>

<script>
export default {
  name: "ubicacion",
  props: {
    usarUbicacionUsuario: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      paisSeleccionado: {},
      provinciaSeleccionada: {},
      localidadSeleccionada: {},

      urlPais: this.$api.URL + "/pais/obtenerTodos",
      urlProvincia: this.$api.URL + "/provincia/obtenerTodos",
      urlLocalidad: this.$api.URL + "/localidad/obtenerTodos"
    };
  },
  mounted() {
    if (this.usarUbicacionUsuario) {
      let pais = {
        nombre: this.$usuario().pais,
        id: this.$usuario().paisID
      };
      let provincia = {
        nombre: this.$usuario().provincia,
        id: this.$usuario().provinciaID
      };
      let localidad = {
        nombre: this.$usuario().localidad,
        id: this.$usuario().localidadID
      };

      this.$refs.pais.seleccionar(pais);
      setTimeout(() => {
        this.$refs.provincia.seleccionar(provincia);
        setTimeout(() => {
          this.$refs.localidad.seleccionar(localidad);
        }, 200);
      }, 200);
    }
  },
  methods: {
    seleccionar(datos) {
      this.$refs.pais.seleccionar(datos.pais);
      setTimeout(() => {
        this.$refs.provincia.seleccionar(datos.provincia);
        setTimeout(() => {
          this.$refs.localidad.seleccionar(datos.localidad);
        }, 200);
      }, 200);
    },

    // Usado por otros componentes para acceder al pais y provincia
    obtenerTodosDatos() {
      return {
        pais: this.paisSeleccionado,
        provincia: this.provinciaSeleccionada,
        localidad: this.localidadSeleccionada
      };
    }
  }
};
</script>
