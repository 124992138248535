<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3>
          <i class="el-icon-star-off"></i>Promoción Vigente
        </h3>
      </div>
      <div class="botones">
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          @click="$refs.modalNuevo.abrir()"
        >Nueva Promoción</el-button>
      </div>
    </div>
    <el-card :body-style="{ padding: '20px' }">
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column :min-width="100">
            <template slot="header">
              <maca-datatable-buscar label="Nombre" v-model="filtroNombre"></maca-datatable-buscar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.nombre"></span>
            </template>
          </el-table-column>
          <el-table-column label="Descripción">
            <template slot-scope="props">
              <span v-text="props.row.descripcion"></span>
            </template>
          </el-table-column>
          <el-table-column label="Fecha Desde">
            <template slot-scope="props">
              <span v-text="$common.formatearFecha(props.row.fechaDesde)"></span>
            </template>
          </el-table-column>
          <el-table-column label="Fecha Hasta">
            <template slot-scope="props">
              <span v-text="$common.formatearFecha(props.row.fechaHasta)"></span>
            </template>
          </el-table-column>
          <el-table-column label="Vigente">
            <template slot-scope="props">
              <el-switch
                  v-model="props.row.vigente"
                  active-text="Si"
                  inactive-text="No"
                  @change="ponerVigente(props.row.id)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                type="default"
                size="small"
                @click="$refs.modalModificar.abrir(props.row.id)"
                round
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button type="danger" size="small" @click="eliminar(props.row.id)" plain round>
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo ref="modalNuevo" @actualizar-tabla="actualizarTabla = true"></modal-nuevo>
    <modal-modificar ref="modalModificar" @actualizar-tabla="actualizarTabla = true"></modal-modificar>
  </div>
</template>

<style></style>

<style scoped></style>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "home",
  components: {
    ModalNuevo,
    ModalModificar
  },
  data() {
    return {
      urlTabla: this.$api.URL + "/promocion/obtenerTodos",
      paramsTabla: [],
      actualizarTabla: true,
      bloquearTabla: true,

      filtroNombre: null
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar la promoción."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { promocionID: id };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/promocion/eliminar",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Promoción borrada con éxito!",
          type: "success"
        });
      } else if (respuestaApi.estado == -3) {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0
        });
      }
      //
    },
    async ponerVigente(id) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { promocionID: id, vigente: 1 };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/promocion/actualizarVigencia",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Promoción puesta como vigente con éxito!",
          type: "success"
        });
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      }
      //
    }
  },
  watch: {
    filtroNombre() {
      if (this.filtroNombre != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "nombre",
          this.filtroNombre
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "nombre");
      }

      this.actualizarTabla = true;
    }
  }
};
</script>
