import Vue from "vue";
import Router from "vue-router";

import Login from "./views/login";
import OlvideContrasena from "./views/olvide_contrasena";
import store from "./store";

Vue.use(Router);

import Home from "./views/home";

import Premios from "./views/premios";
import Participantes from "./views/participantes";
import Ganadores from "./views/ganadores";
import Calendario from "./views/calendario";
import Canjear from "./views/canjear";

import AjustesProvincias from "./views/ajustes/provincias";
import AjustesLocalidades from "./views/ajustes/localidades";
import AjustesTiposPremio from "./views/ajustes/tipos_premio";
import AjustesPromocionVigente from "./views/ajustes/promocion_vigente";
import AjustesComercial from "./views/ajustes/comerciales";

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      beforeEnter: ifAuthenticated
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      beforeEnter: ifNotAuthenticated
      // props: { style: { background: "#607d8b" } }
    },
    {
      path: "/canjear-codigo",
      name: "Canjear",
      component: Canjear,
      props: { layout: false }
    },
    {
      path: "/olvidecontrasena",
      name: "OlvideContrasena",
      component: OlvideContrasena,
      beforeEnter: ifNotAuthenticated
      // props: { style: { background: "#607d8b" } }
    },
    {
      path: "/premios",
      name: "Premios",
      component: Premios,
      beforeEnter: (to, from, next) => {
        ifAuthenticated(to, from, next);
      },
      props: {}
    },
    {
      path: "/participantes",
      name: "Participantes",
      component: Participantes,
      beforeEnter: (to, from, next) => {
        ifAuthenticated(to, from, next);
      },
      props: {}
    },
    {
      path: "/ganadores",
      name: "Ganadores",
      component: Ganadores,
      beforeEnter: (to, from, next) => {
        ifAuthenticated(to, from, next);
      },
      props: {}
    },

    {
      path: "/calendario",
      name: "Calendario",
      component: Calendario,
      beforeEnter: (to, from, next) => {
        ifAuthenticated(to, from, next);
      },
      props: {}
    },
    {
      path: "/ajustes/provincias",
      name: "AjustesProvincias",
      component: AjustesProvincias,
      beforeEnter: (to, from, next) => {
        ifAuthenticated(to, from, next);
      },
      props: {}
    },
    {
      path: "/ajustes/localidades",
      name: "AjustesLocalidades",
      component: AjustesLocalidades,
      beforeEnter: (to, from, next) => {
        ifAuthenticated(to, from, next);
      },
      props: {}
    },
    {
      path: "/ajustes/tipos_premio",
      name: "AjustesTiposPremio",
      component: AjustesTiposPremio,
      beforeEnter: (to, from, next) => {
        ifAuthenticated(to, from, next);
      },
      props: {}
    },
    {
      path: "/ajustes/promocion_vigente",
      name: "AjustesPromocionVigente",
      component: AjustesPromocionVigente,
      beforeEnter: (to, from, next) => {
        ifAuthenticated(to, from, next);
      },
      props: {}
    },
    {
      path: "/ajustes/comerciales",
      name: "AjustesComercial",
      component: AjustesComercial,
      beforeEnter: (to, from, next) => {
        ifAuthenticated(to, from, next);
      },
      props: {}
    }
  ]
});
