var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: { titulo: "Historial de Estados", "confirmar-close": false }
        },
        [
          _vm.datosApi
            ? _c(
                "el-timeline",
                { attrs: { reverse: false } },
                _vm._l(_vm.datosApi, function(estado, index) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: index,
                      attrs: {
                        type: _vm.obtenerTipoHito(estado),
                        timestamp: _vm.$common.formatearFechaHora(estado.fecha)
                      }
                    },
                    [_vm._v(_vm._s(estado.estadoCanje))]
                  )
                }),
                1
              )
            : _c("div", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.cargando,
                    expression: "cargando"
                  }
                ],
                staticStyle: { height: "150px" }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }