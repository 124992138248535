var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-date-picker", {
        attrs: {
          type: "date",
          format: "dd/MM/yyyy",
          "value-format": "dd/MM/yyyy",
          placeholder: _vm.placeholder,
          "picker-options": _vm.fechaOpciones,
          disabled: _vm.disabled
        },
        model: {
          value: _vm.fechaSeleccionada,
          callback: function($$v) {
            _vm.fechaSeleccionada = $$v
          },
          expression: "fechaSeleccionada"
        }
      }),
      _vm.stepTimePicker != "00:1"
        ? _c("el-time-select", {
            attrs: {
              placeholder: "Hora",
              format: "HH:mm",
              "value-format": "HH:mm",
              "picker-options": {
                start: "00:00",
                step: _vm.stepTimePicker,
                end: "23:59"
              },
              disabled: _vm.fechaSeleccionada == "" || _vm.disabled
            },
            model: {
              value: _vm.horaSeleccionada,
              callback: function($$v) {
                _vm.horaSeleccionada = $$v
              },
              expression: "horaSeleccionada"
            }
          })
        : _c("el-time-picker", {
            attrs: {
              placeholder: "Hora",
              format: "HH:mm",
              "value-format": "HH:mm",
              disabled: _vm.fechaSeleccionada == "" || _vm.disabled
            },
            model: {
              value: _vm.horaSeleccionada,
              callback: function($$v) {
                _vm.horaSeleccionada = $$v
              },
              expression: "horaSeleccionada"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }