import Vue from "vue";

import MacaSelectBox from "./form/select_box";
import MacaSelectBoxMultiple from "./form/select_box_multiple";
import MacaSelectBoxCliente from "./form/select_box/cliente.vue";
import MacaInputFecha from "./form/fecha";
import MacaInputRangoFecha from "./form/rango_fecha";
import MacaInputFechaHora from "./form/fecha_hora";
import MacaUbicacion from "./form/ubicacion";
import MacaBotonGuardar from "./form/boton_guardar";
import MacaDatatable from "./datatable";
import MacaDatatableBuscar from "./datatable/buscar.vue";
import MacaDatatableBuscarSelect from "./datatable/buscar_select.vue";
import MacaDatatableFiltrarRangoFecha from "./datatable/filtrar_rangofecha.vue";
import MacaMensajeError from "./mensaje/error";
import MacaModal from "./modal";
import MostrarMensajeError from "./mensaje/error/mostrar_mensaje";

Vue.component("maca-select-box", MacaSelectBox);
Vue.component("maca-select-box-multiple", MacaSelectBoxMultiple);
Vue.component("maca-select-box-cliente", MacaSelectBoxCliente);
Vue.component("maca-input-fecha", MacaInputFecha);
Vue.component("maca-input-rango-fecha", MacaInputRangoFecha);
Vue.component("maca-input-fechahora", MacaInputFechaHora);
Vue.component("maca-ubicacion", MacaUbicacion);
Vue.component("maca-boton-guardar", MacaBotonGuardar);
Vue.component("maca-datatable", MacaDatatable);
Vue.component("maca-datatable-buscar", MacaDatatableBuscar);
Vue.component("maca-datatable-buscar-select", MacaDatatableBuscarSelect);
Vue.component("maca-mensaje-error", MacaMensajeError);
Vue.component("maca-modal", MacaModal);
Vue.component(
    "maca-datatable-filtrar-rangofecha",
    MacaDatatableFiltrarRangoFecha
);

Vue.prototype.$mostrarMensajeError = MostrarMensajeError;
