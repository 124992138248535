<template>
  <div>
    <maca-modal
      titulo="Datos de Calendario"
      :confirmar-close="false"
      ref="modal"
    >
      <table v-if="datos" class="tabla-info">
        <tbody>
          <tr>
            <th>Promocion</th>
            <td v-text="datos.promocion"></td>
          </tr>
          <tr>
            <th>Fecha Desde</th>
            <td v-text="$common.formatearFecha(datos.fechaDesde)"></td>
          </tr>
          <tr>
            <th>Fecha Hasta</th>
            <td v-text="$common.formatearFecha(datos.fechaHasta)"></td>
          </tr>
          <tr>
            <th>Hora Desde</th>
            <td v-text="datos.horaDesde"></td>
          </tr>
          <tr>
            <th>Hora Hasta</th>
            <td v-text="datos.horaHasta"></td>
          </tr>
          <tr>
            <th>Provincia</th>
            <td v-text="datos.provincia"></td>
          </tr>
          <tr>
            <th>Localidad</th>
            <td v-text="datos.localidad"></td>
          </tr>
          <tr>
            <th>Premios</th>
            <td>
              <div v-if="datos.detalleCalendario != null">
                <li v-for="itemDC in datos.detalleCalendario">
                  <b>{{ itemDC.tipoPremio }}</b> | <b>Cant. Total:</b>
                  {{ itemDC.cantidadPremioDisponible }} |
                  <b>Cant. Canjeada:</b> {{ itemDC.cantidadPremioCanjea }}
                </li>
              </div>
            </td>
          </tr>
          <tr>
            <th>Modo</th>
            <td>
              <span v-if="datos.salidaPorMinuto === 1">Por <b>Minutos</b></span>
              <span v-if="datos.salidaPorCodigo === 1"
                >Por <b>Códigos Canjeados</b></span
              >
            </td>
          </tr>
          <tr>
            <th>Configuración</th>
            <td>
              <p v-if="datos.salidaPorCodigo == 1">
                Saldrá <b>{{ datos.valorSalidaPremio }} premios</b> cada
                <b>{{ datos.valorSalidaCantidad }} códigos canjeados</b>
              </p>
              <p v-else>
                Saldrá <b>{{ datos.valorSalidaPremio }}</b> premios cada
                <b>{{ datos.valorSalidaCantidad }} minutos</b>
              </p>
            </td>
          </tr>
          <tr>
            <th>Cantidad Total</th>
            <td v-text="datos.cantidadPremioTotal"></td>
          </tr>
          <tr>
            <th>Cantidad Canjeada</th>
            <td v-text="datos.cantidadPremioCanjeado"></td>
          </tr>
          <tr>
            <th>Cantidad Restante</th>
            <td v-text="datos.cantidadPremioRestante"></td>
          </tr>
        </tbody>
      </table>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "ver",
  data() {
    return {
      datos: null,
    };
  },
  methods: {
    abrir(datos) {
      this.datos = datos;
      this.$refs.modal.abrir();
    },
  },
};
</script>
