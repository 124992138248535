/* eslint-disable promise/param-names */
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from "../actions/auth";

// Se cambio de utilizar strings a objetos JSON
// por lo que es posible que algunos usuarios tengan roles como string en vez de
// json
function intentarParsearRoles() {
  let roles = "";
  try {
    roles = JSON.parse(window.localStorage.getItem("roles"));
  } catch (error) {
    return roles;
  }

  return roles;
}

const state = {
  token: window.localStorage.getItem("token") || "",
  roles: intentarParsearRoles(),
  usuario: JSON.parse(window.localStorage.getItem("usuario")) || "",
  status: "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  token: state => state.token,
  roles: state => state.roles,
  usuario: state => state.usuario,
  authStatus: state => state.status
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      window.localStorage.setItem("token", user.token);
      window.localStorage.setItem("roles", JSON.stringify(user.roles));
      window.localStorage.setItem("usuario", JSON.stringify(user.usuario));

      commit(AUTH_SUCCESS, user);
      resolve(user);
    });
  },
  [AUTH_LOGOUT]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_LOGOUT);
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("roles");
      window.localStorage.removeItem("usuario");
      resolve();
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, user) => {
    state.status = "success";
    state.token = user.token;
    state.roles = user.roles;
    state.usuario = user.usuario;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
    state.roles = "";
    state.usuario = null;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
