var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Códigos Canjeados por Provincia",
            confirmarClose: false
          }
        },
        [
          _c("h6", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v("\n      Total códigos: "),
            _c("b", [_vm._v(_vm._s(_vm.totalCodigos))])
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cargando,
                  expression: "cargando"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.displayData }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "provincia", label: "Provincia" }
              }),
              _c("el-table-column", {
                attrs: { sortable: "" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function(props) {
                      return [
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.sort("cantidad")
                              }
                            }
                          },
                          [_vm._v("Cantidad")]
                        )
                      ]
                    }
                  },
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(_vm._s(props.row.cantidad))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "cantidadParticipUnicos",
                  label: "Cantidad Participantes"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "detalleLocalidad",
                  label: "Detalle por Localidad"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "small",
                              plain: "",
                              round: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.modalDetCodCanjeadosPorLocalidad.abrir(
                                  props.row.detalleLocalidad,
                                  props.row.provincia,
                                  props.row.cantidad
                                )
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-zoom-in" })]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("br"),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  "page-size": _vm.pageSize,
                  total: _vm.datos.length
                },
                on: { "current-change": _vm.handleCurrentChange }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("modal-det-cod-canjeados-por-localidad", {
        ref: "modalDetCodCanjeadosPorLocalidad"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }