<template>
  <div>
    <maca-modal titulo="Códigos Canjeados por Día (agrupado por fecha)" ref="modal" :confirmarClose="false">
      <h6 style="margin-left: 10px;">Día <b>{{ diaSemana }}</b> - Total códigos (únicos del día): <b>{{ totalCodigos }}</b></h6>

      <el-table
          :data="displayData"
          style="width: 100%"
          v-loading="cargando">
        <el-table-column
            sortable>
          <template slot="header" slot-scope="props">
            <div @click="sort('fecha')">Fecha</div>
          </template>
          <template slot-scope="props">{{ $common.formatearFecha(props.row.fecha) }}</template>
        </el-table-column>
        <el-table-column
            sortable>
          <template slot="header" slot-scope="props">
            <div @click="sort('cantidadCodigos')">Cantidad</div>
          </template>
          <template slot-scope="props">{{ props.row.cantidadCodigos }}</template>
        </el-table-column>
      </el-table>
      <br>
      <div style="text-align: center">
        <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :total="datos.length">
        </el-pagination>
      </div>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "detCodCanjeadosDiariosAgrupadoFecha",
  data() {
    return {
      datos: [],
      promocionID: 0,
      diaNro: 0,
      diaSemana: '',
      totalCodigos: 0,
      page: 1,
      pageSize: 10,
      currentSort: 'fecha',
      currentSortDir: 'desc',
      cargando: false,
    };
  },
  methods: {
    abrir(promocionID, diaNro, diaSemana, totalCodigos) {
      this.promocionID = promocionID
      this.diaNro = diaNro
      this.diaSemana = diaSemana
      this.totalCodigos = totalCodigos
      this.page = 1
      this.datos = []
      this.$refs.modal.abrir()

      this.obtenerDatos()
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    async obtenerDatos() {
      this.cargando = true

      let urlParams = "?promocionID=" + this.promocionID
      + "&diaNro=" + this.diaNro
      + "&agrupadoPorFecha=1"

      let respuestaApi = await this.$api.get(this.$api.URL + "/canje/obtenerDatosEstadisticaPorDiaCodigo"
          + urlParams,
          this.$usuarioToken());

      this.cargando = false

      if (respuestaApi != null) {
        this.datos = respuestaApi[0].detallePorFecha != null ? respuestaApi[0].detallePorFecha : []
        return true;

      } else {
        this.$message({
          message: "Error al obtener datos",
          type: "error",
          showClose: true,
          duration: 0
        });
      }

      return false;
    }
  },
  computed: {
    displayData:function() {
      return this.datos.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.page-1)*this.pageSize;
        let end = this.page*this.pageSize;
        if(index >= start && index < end) return true;
      });
    }
  },
};
</script>
