<template>
  <div>
    <maca-modal
      titulo="Códigos Canjeados por Provincia"
      ref="modal"
      :confirmarClose="false"
    >
      <h6 style="margin-left: 10px">
        Total códigos: <b>{{ totalCodigos }}</b>
      </h6>

      <el-table :data="displayData" style="width: 100%" v-loading="cargando">
        <el-table-column prop="provincia" label="Provincia"> </el-table-column>
        <el-table-column sortable>
          <template slot="header" slot-scope="props">
            <div @click="sort('cantidad')">Cantidad</div>
          </template>
          <template slot-scope="props">{{ props.row.cantidad }}</template>
        </el-table-column>
        <el-table-column
          prop="cantidadParticipUnicos"
          label="Cantidad Participantes"
        >
        </el-table-column>
        <el-table-column prop="detalleLocalidad" label="Detalle por Localidad">
          <template slot-scope="props">
            <el-button
              type="primary"
              size="small"
              plain
              @click="
                $refs.modalDetCodCanjeadosPorLocalidad.abrir(
                  props.row.detalleLocalidad,
                  props.row.provincia,
                  props.row.cantidad
                )
              "
              round
            >
              <i class="el-icon-zoom-in"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br />
      <div style="text-align: center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :total="datos.length"
        >
        </el-pagination>
      </div>
    </maca-modal>
    <modal-det-cod-canjeados-por-localidad
      ref="modalDetCodCanjeadosPorLocalidad"
    ></modal-det-cod-canjeados-por-localidad>
  </div>
</template>

<script>
import ModalDetCodCanjeadosPorLocalidad from "./det_cod_canjeados_por_localidad";

export default {
  name: "detCodCanjeadosPorProvincia",
  components: {
    ModalDetCodCanjeadosPorLocalidad,
  },
  data() {
    return {
      datos: [],
      promocionID: 0,
      totalCodigos: 0,
      page: 1,
      pageSize: 10,
      currentSort: "cantidad",
      currentSortDir: "desc",
      cargando: false,
    };
  },
  methods: {
    abrir(promocionID, totalCodigos) {
      this.promocionID = promocionID;
      this.totalCodigos = totalCodigos;
      this.page = 1;
      this.datos = [];
      this.$refs.modal.abrir();

      this.obtenerDatos();
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    async obtenerDatos() {
      this.cargando = true;

      let urlParams = "?promocionID=" + this.promocionID;

      let respuestaApi = await this.$api.get(
        this.$api.URL +
          "/canje/obtenerDatosEstadisticaPorProvinciaLocalidad" +
          urlParams,
        this.$usuarioToken()
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.datos = respuestaApi;
        return true;
      } else {
        this.$message({
          message: "Error al obtener datos",
          type: "error",
          showClose: true,
          duration: 0,
        });
      }

      return false;
    },
  },
  computed: {
    displayData: function () {
      return this.datos
        .sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        })
        .filter((row, index) => {
          let start = (this.page - 1) * this.pageSize;
          let end = this.page * this.pageSize;
          if (index >= start && index < end) return true;
        });
    },
  },
};
</script>
