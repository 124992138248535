<template>
  <div>
    <maca-modal
      titulo="Historial de Estados"
      :confirmar-close="false"
      ref="modal"
    >
      <el-timeline v-if="datosApi" :reverse="false">
        <el-timeline-item
          v-for="(estado, index) in datosApi"
          :key="index"
          :type="obtenerTipoHito(estado)"
          :timestamp="$common.formatearFechaHora(estado.fecha)"
          >{{ estado.estadoCanje }}</el-timeline-item
        >
      </el-timeline>
      <div v-else style="height: 150px" v-loading="cargando"></div>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "historial-ganador",
  data() {
    return {
      datosApi: null,
      cargando: false,
      id: 0,

      formRules: {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir(id) {
      this.$refs.modal.abrir();

      this.id = id;
      this.datosApi = null;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    obtenerTipoHito(estado) {
      if (estado.estadoCanje.toUpperCase() == "REALIZADO") {
        return "success";
      } else if (estado.estadoCanje.toUpperCase() == "RETORNADO") {
        return "warning";
      } else if (estado.estadoCanje.toUpperCase() == "ENVÍO ENTREGADO") {
        return "warning";
      } else if (estado.estadoCanje.toUpperCase() == "ENVÍO EN TRÁNSITO") {
        return "info";
      } else if (estado.estadoCanje.toUpperCase() == "RETORNADO") {
        return "primary";
      } else {
        return "default";
      }
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$api.get(
        this.$api.URL +
          "/canjeHistorialEstado/obtenerTodosParaCanje?canjeID=" +
          this.id,
        this.$usuarioToken()
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.datosApi = respuestaApi;
      } else {
        this.cerrar();
      }
    },
  },
};
</script>
