<template>
  <div>
    <maca-modal
      titulo="Códigos Canjeados por Localidad"
      ref="modal"
      :confirmarClose="false"
    >
      <h6 style="margin-left: 10px">
        Provincia <b>{{ provincia }}</b> - Total códigos:
        <b>{{ totalCodigos }}</b>
      </h6>

      <el-table :data="displayData" style="width: 100%">
        <el-table-column prop="localidad" label="Localidad"> </el-table-column>
        <el-table-column sortable>
          <template slot="header" slot-scope="props">
            <div @click="sort('cantidad')">Cantidad</div>
          </template>
          <template slot-scope="props">{{ props.row.cantidad }}</template>
        </el-table-column>
        <el-table-column
          prop="cantidadParticipUnicos"
          label="Cantidad Participantes"
        >
        </el-table-column>
      </el-table>
      <br />
      <div style="text-align: center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :total="datos.length"
        >
        </el-pagination>
      </div>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "detCodCanjeadosPorLocalidad",
  data() {
    return {
      datos: [],
      provincia: "",
      totalCodigos: 0,
      page: 1,
      pageSize: 10,
      currentSort: "cantidad",
      currentSortDir: "desc",
    };
  },
  methods: {
    abrir(datos, provincia, totalCodigos) {
      this.datos = datos;
      this.provincia = provincia;
      this.totalCodigos = totalCodigos;
      this.page = 1;
      this.$refs.modal.abrir();
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
  },
  computed: {
    displayData: function () {
      return this.datos
        .sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        })
        .filter((row, index) => {
          let start = (this.page - 1) * this.pageSize;
          let end = this.page * this.pageSize;
          if (index >= start && index < end) return true;
        });
    },
  },
};
</script>
