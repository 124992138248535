<template>
  <div class="contenedor-tabla">
    <transition @after-enter="refresh_anim = false">
      <div v-if="refresh_anim" class="contenedor-refresh-anim scale-in-ver-top-fade"></div>
    </transition>
    <data-tables-server
      v-loading="cargando"
      :data="tableData"
      :current-page.sync="pagActual"
      :pagination-props="paginationProps"
      :pageSize="paginationProps.pageSizes[0]"
      @query-change="cambiarPagina"
      :rowClassName="rowClassName"
      :defaultSortData="defaultSortProp"
    >
      <slot></slot>
      <template slot="append">
        <slot name="append"></slot>
      </template>
    </data-tables-server>
  </div>
</template>

<style></style>

<script>
import DataTablesServer from "../data-tables-server/components/DataTablesServer";

export default {
  // nota: Usar los props actualizar y bloquear con sync
  props: {
    url: {
      type: String,
      default: null
    },
    params: {
      type: Array,
      default: null
    },
    usarData: {
      type: String,
      default: null
    },
    datos: {
      type: Object,
      default: null
    },
    actualizar: {
      type: Boolean,
      default: null
    },
    bloquear: {
      type: Boolean,
      default: null
    },
    sortParams: {
      type: Object,
      default: null
    },
    defaultSortProp: {
      type: Object,
      default: () => {
        return { prop: "", order: "" };
      }
    },
    rowClassName: {
      type: Function,
      default: null
    },
    timeoutHabilitado: {
      type: Boolean,
      default: true
    },

    // Para atributos del frontend que son necesarios
    // agregar antes de que se carguen los datos al datatable
    // ej: checked
    // Esto pasa porque la tabla no se vuelve a renderizar si un atributo
    // que no estaba antes del renderizado cambia
    preAgregarAtributos: {
      type: Object,
      default: null
    }
  },
  components: {
    DataTablesServer
  },
  name: "maca-datatable",
  data() {
    return {
      datosApi: null,
      pagActual: 1,
      sort: {
        prop: "",
        order: ""
      },
      cargando: false,
      refresh_anim: false
    };
  },
  created() {
    // si hay columna por defecto para hacer sort, usarla
    // la var this.sort es local, de este componente.
    // defaultSortProp tambien se usa para el componente dataTablesServer
    if (this.defaultSortProp != null) {
      this.sort.prop = this.defaultSortProp.prop;
      this.sort.order = this.defaultSortProp.order;
    }

    // la funcion cambiar pagina hace la peticion a la api
    this.cambiarPagina({
      page: this.pagActual,
      sort: this.sort
    });
  },
  computed: {
    // Obtiene los datos a mostrar en la tabla, osea el componente, desde los datos de API
    tableData() {
      if (this.datosApi != null) {
        // los datos estan paginados?
        if (this.datosApi.datos != null) {
          // En el caso de obtenerTodos productos, es datos.data en vez de datos
          // TODO cambiar a datos en API
          if (Array.isArray(this.datosApi.datos)) {
            return this.datosApi.datos;
          } else {
            return this.datosApi.datos.data;
          }
        } else {
          return this.datosApi;
        }
      } else {
        return [];
      }
    },

    // La configuracion necesaria para el componente data-tables-server
    paginationProps() {
      /*
        IMPORTANTE: Tener en cuenta que paginationProps sólo se utiliza para
        el componente de paginación. No controla cuántos items se ven en el datatable.
        Actualmente se muestran en el datatable todos los items enviados desde la API,
        es decir, la cantidad de items a mostrar por página lo controla la API
        
        La api trae:
        {
            pagActual:1
            pagTotal:2
            pagTotalItems:21
        }
        Para hacer que la paginación funcione, sincronizar props.pageSizes con pagTotal.
        Porque props.pageSizes controla los botones de paginación, junto con props.total.
  
        Ej:
        Si pageSizes = 10 y total = 15 entonces se mostrará para seleccionar 2 páginas.
        si pageSizes = 1 y total = 5 entonces se mostrará para seleccionar 5 páginas.
  
        IMPORTANTE: Repitiendo lo de la nota anterior, pagSizes y total no
        controlan la cantidad de items mostrados en el datatable, sólo la cantidad
        de paginas a seleccionar.
        */
      let props = {
        background: true,
        pageSizes: [1],
        layout: "prev, pager, next",
        total: 1
      };

      if (this.datosApi != null && this.datosApi.pagTotal != null) {
        props.total = this.datosApi.pagTotal;
      }

      return props;
    }
  },
  methods: {
    // Llamada a API con la URL proporcionada en props
    // Si existen parametros, añadirlos
    get() {
      let urlParams = "?nroPagina=" + this.pagActual;

      // Procesar parametros para ordenar la tabla
      // MACACRM usa sortParam, MACACRM Agil usa sort y order
      // La documentacion tiene mas detalles de esto
      if (this.sortParams == null) {
        // Macacrm AGIL
        if (this.sort.prop != "" && this.sort.prop != null) {
          urlParams = urlParams + "&sort=" + this.sort.prop;
        }
        if (this.sort.order != "" && this.sort.order != null) {
          urlParams = urlParams + "&order=" + this.sort.order;
        }
      } else {
        //Macacrm
        Object.keys(this.sortParams).forEach(param => {
          if (param == this.sort.prop) {
            urlParams = urlParams + "&" + this.sortParams[param] + "=";
            if (this.sort.order == "ascending") {
              urlParams = urlParams + "ASC";
            } else {
              urlParams = urlParams + "DESC";
            }
          }
        });
      }

      this.params.forEach(element => {
        urlParams = urlParams + "&" + element;
      });

      this.cargando = true;
      if (this.timeoutHabilitado) {
        return this.$api.get(this.url + urlParams, this.$usuarioToken());
      } else {
        return this.$api.get(this.url + urlParams, this.$usuarioToken(), 0);
      }
    },

    // Guarda datos despues de recibirlos de la API
    // Emite eventos que pueden ser de utilidad, y desbloquea la tabla
    procesarDatosApi(datos) {
      // agregar atributos que el frontend necesita
      // por ejemplo, un atributo "checked" si se quiere seleccionar las filas
      if (this.preAgregarAtributos) {
        datos.datos.forEach(fila => {
          Object.keys(this.preAgregarAtributos).forEach(atributo => {
            fila[atributo] = this.preAgregarAtributos[atributo];
          });
        });
      }

      // si se debe usar una variable "datos" diferente de lo obtenido de la API
      // se usa para cuando la url trae un objeto, y la tabla esta dentro de éste
      if (this.usarData != null) {
        this.datosApi = datos[this.usarData];
      } else {
        this.datosApi = datos;
      }

      this.refresh_anim = true;
      this.cargando = false;
      if (Array.isArray(datos)) {
        if (datos.length > 0) {
          this.$emit("tabla-no-vacia");
        }
      } else {
        if (datos != null && datos.datos != null && datos.datos.length > 0) {
          this.$emit("tabla-no-vacia");
        }
      }
      this.$emit("tabla-cargada", this.datosApi);
    },

    // Maneja los botones de cambio de página
    // *Emitir actualizar = false en cada posibilidad
    // sino el datatable se traba en actualizar = true en algunas ocaciones.
    // Por ejemplo, al setear actualizar = true al crear la tabla
    // Tener en cuenta que el proceso es asincronico. La tabla debe estar bloqueada/actualizando
    // hasta que la consulta termine
    cambiarPagina(queryInfo) {
      this.pagActual = queryInfo.page;

      // verificar que sea un sort con datos.
      // porque hay un problema al usar columnas con sort por defecto
      // al cambiar de pagina, sort no tiene ni prop ni order
      if (queryInfo.sort.prop) {
        this.sort = queryInfo.sort;
      }

      // si no se puede realizar consulta, desbloquear tabla
      // y no hacer nada
      if (this.url == "" || this.url == null) {
        this.$emit("update:actualizar", false);
        this.$emit("update:bloquear", false);
        return;
      }

      this.get().then(result => {
        this.procesarDatosApi(result);
        this.$emit("update:actualizar", false);
        this.$emit("update:bloquear", false);
      });
    }
  },
  watch: {
    // Prop para actualizar la tabla
    actualizar: {
      handler: function() {
        if (this.actualizar) {
          this.cambiarPagina({
            page: this.pagActual,
            sort: this.sort
          });
        }
      }
    },

    // Prop para bloquear la tabla
    bloquear: {
      handler: function() {
        this.cargando = this.bloquear;
      }
    }
  }
};
</script>

<style scoped>
.contenedor-tabla {
  overflow: hidden;
  position: relative;
}
.contenedor-tabla-cargando {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background: rgba(255, 255, 255, 0.5);
}

.contenedor-refresh-anim {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  pointer-events: none;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+96,000000+100&0+0,0.5+96,0+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.1) 99%,
    rgba(0, 0, 0, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.1) 99%,
    rgba(0, 0, 0, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.1) 99%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

.scale-in-ver-top-fade {
  -webkit-animation: scale-in-ver-top 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-ver-top 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2018-10-22 11:40:23
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
</style>
