<template>
  <div class="login-page">
    <div class="login-box">
      <div class="logo" style="text-align: center;">
        <a href="javascript:void(0);">Home</a>
        <br />
        <small>por</small>
        <br />
        <img src="../../assets/images/macamedia.png" style="height: 30px;" />
      </div>
    </div>
    <el-card v-loading="cargando" :body-style="{ padding: '10px' }">
      <div slot="header">
        <span>Ingrese su correo</span>
      </div>
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="email">
          <el-input
            placeholder="Email"
            v-model="form.email"
            :autofocus="true"
            @keyup.enter.native="forgotPassword"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div style="display: flex">
            <el-button
              style="margin:auto; margin-left: 0px"
              type="primary"
              @click="forgotPassword"
              >Enviar</el-button
            >
            <el-button
              style="margin:auto; margin-right: 0px;"
              type="default"
              @click="$router.push('/login')"
              size="small"
              >Volver</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<style scoped>
.login-page {
  background: none;
  height: 450px;
  width: 600px;
  margin: auto;
  vertical-align: middle;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>

<script>
import { AUTH_REQUEST } from "../../store/actions/auth";

export default {
  name: "login",
  data() {
    return {
      form: {
        email: ""
      },

      cargando: false
    };
  },
  computed: {
    rules() {
      let rules = {
        email: [
          {
            required: true,
            message: "Por favor introduzca su correo.",
            trigger: "change"
          },
          {
            type: "email",
            message: "El correo no es válido.",
            trigger: "change"
          }
        ]
      };

      return rules;
    }
  },
  methods: {
    forgotPassword: function() {
      this.$refs.form.validate((respuesta, data) => {
        if (respuesta) {
          let params = {
            email: this.form.email
          };

          this.cargando = true;

          this.$api
            .post(this.$api.URL + "/auth/forgotPassword", params)
            .then(respuesta => {
              this.postRealizado(respuesta);
            });
        }
      });
    },

    postRealizado(respuesta) {
      this.cargando = false;
      if (respuesta.estado != 1) {
        if (respuesta.estado == -2) {
          this.$message({
            message: "El email ingresado no existe.",
            type: "error"
          });
        } else {
          this.$message({
            message: "Ocurrió un error. Reintente.",
            type: "error"
          });
        }
        return;
      }

      this.$message({
        message: "Correo enviado. Revise su casilla de correo.",
        type: "success",
        showClose: true
      });

      this.$router.push("/");
    }
  }
};
</script>
