var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    {
      class: {
        "boton-cargando": _vm.cargando && !_vm.soloIcono,
        "boton-cargando-soloicono": _vm.soloIcono
      },
      attrs: {
        loading: _vm.cargando,
        "element-loading-spinner": "el-icon-loading",
        "element-loading-custom-class": "loading-class",
        type: _vm.type,
        disabled: _vm.disabled || _vm.cargando,
        size: _vm.size,
        icon: _vm.iconClassIfActivo,
        plain: _vm.plain
      },
      on: { click: _vm.handleClick }
    },
    [_c("span", { domProps: { textContent: _vm._s(_vm.textoActual) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }