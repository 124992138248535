import Vue from "vue"
import App from "./components/App"
import router from "./router"
import store from "./store"
import "./registerServiceWorker"

import { sync } from "vuex-router-sync"

// Vue Cli plugins
import "./plugins/element.js"

// Componentes de macamedia
import "./components/macamedia"

// Utils de macamedia
import "./utils"

// Para los input de telefonos
import VueTheMask from "vue-the-mask"

// Calendario
import VCalendar from 'v-calendar';

Vue.use(VueTheMask)
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />              // ...other defaults
});

// Prototipos
const moment = require("moment")
Vue.prototype.$moment = moment
Vue.prototype.$moment.locale("es")
Vue.prototype.$esMovil = () => {
  return window.outerWidth <= 768
}

Vue.config.productionTip = false
sync(store, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")

export { App, router, store }
